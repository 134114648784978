.field-wrapper {
  //this allows for the datepicker calendar to appear automatically when you click anywhere in the input box for webkit browsers (Chrome, Safari, Edge)
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

}