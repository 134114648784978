.ctas {
	$breakpoint_to_horizontal : 50em;
	background-color: $color_white;
	border-top: 1px solid $color_alto;
	display: block;

	@media (min-width: $breakpoint_to_horizontal) {
		@include flexbox();
	}

	@media only print {
		display: none;
	}

	.cta {
		padding-top: 5.5rem;
		@include scale(padding-right, 1.5rem, 20em, 4rem, 50em);
		padding-bottom: 5.5rem;
		@include scale(padding-left, 1.5rem, 20em, 4rem, 50em);
		text-align: center;

		header {
			max-width: 100%;
			margin-bottom: 0.625rem;
			color: $color_secondary_purple;
			font-size: 1.75rem;
			letter-spacing: .0314em;
		}

		&.no-padding {
			padding-top: 0;
			padding-bottom: 0;
		}

		&.small-padding {
			padding-top: 2rem;
			padding-bottom: 0;
		}

		@media (min-width: $breakpoint_to_horizontal) {
			 flex: (1 1 auto);
			padding: 5.5rem 2.5rem;
		}


			header {
				flex: 1 1 auto;
				max-width: 100%;
				margin-bottom: .625rem;
				color: $color_secondary_purple;
				font-size: 1.75rem;
				letter-spacing: .0314em;
			}

		  p {
			 flex:(1 1 auto);
			  max-width: 100%;
			  max-width: 36.25rem;
			  margin: 0 auto;
			  font-size: 1.125rem;
			  line-height: 1.5556em;
		  }

		.button {
			flex: (1 1 auto);
			margin-top: 2.75rem;
			border-radius: 8px;
			cursor: pointer;
		}

		& + .cta {
			border-top: 1px solid $color_alto;

			@media (min-width: $breakpoint_to_horizontal) {
				border-top: 0;
				border-left: 1px solid $color_alto;
			}
		}
	}
}
#keep_searching {
	border-bottom: 1px solid #dbdbdb;
}

.provider-search-disclaimer {
	text-align: center;
	padding: 2.5rem;
	background-color: #ffffff;
	header {
		margin-bottom: .625rem;
		color: #5d5681;
		font-size: 1.75rem;
		letter-spacing: .0314em;
	}
	p {
		font-size: 1.125rem;
		line-height: 1.5556em;
	}
	> div {
		margin: 0 auto;
		max-width: 865px;
	}

}