/**
 *		Overrides
 */
@import '../../../node_modules/fancybox/dist/jquery.fancybox.min';
.fancybox-button {
	min-width: 0;

	&:not(.fancybox-button--close) {
		float: none;
	}
}