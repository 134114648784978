label.radio-group-option {
	display: flex;
	margin-bottom: 1rem;

	.option-label {
		flex: 1 1 auto;
		order: 2;
		min-width: calc(100% - 3.0625rem);
		width: calc(100% - 3.0625rem);
		max-width: calc(100% - 3.0625rem);
		margin-bottom: 0;
	}

	.option-wrapper {
		flex: 0 0 auto;
		order: 1;
		line-height: 0;

		.radio {
			position: relative;
			display: inline-block;
			width: 1.625rem;
			height: 1.625rem;
			margin-right: 1.5rem;
			border: $input_border_thickness solid $color_input_border;
			border-radius: 50%;
			cursor: pointer;
			transition: all .25s;

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: .75rem;
				height: .75rem;
				background-color: $color_white;
				border-radius: 50%;
				opacity: 0;
				transform: translate(-50%, -50%);
				transition: all .25s;
			}
		}

		input[type=radio] {
			position: absolute;
			opacity: 0;
			z-index: -1;

			&:checked + .radio {
				background-color: $color_radio_background;
				border-color: $color_radio_background;

				&:after {
					opacity: 1;
				}
			}
		}
	}
}