span.tag {
    background-color: $color_carrot_orange;
    color: white;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    font-size: 60%;

    &.purple {
        background-color: $color_comet;
    }

    &.green {
        background-color: $color_mantis;
    }

    &.white-orange {
        background-color: white;
        color: $color_carrot_orange;;
    }
}
