$message_center_to_desktop : 82em;

.message_center_nav {
	margin-bottom: 3rem;

	h1 {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		margin-left: 1.5rem;


		span {
			color: $color_comet;
			font-weight: 400;
			font-size: 2rem;
			letter-spacing: .0109375em;
		}

		a {
			padding: 0 2rem;
			color: $color_white;
			font-weight: 600;
			font-size: .875rem;
			background-color: $color_carrot_orange;
			border-radius: 1000rem;
		}
	}
}

.main_message_center {
	width: 100%;
	background-color: $color_white;
	border: 1px solid $color_mercury;
	margin-left: 1.5rem;

	@media (min-width: $message_center_to_desktop) {
		@include flexbox();
		height: 43.75rem;
	}

	+ .main_message_center {
		margin-top: 2rem;
	}

	> aside {
		@media (min-width: $message_center_to_desktop) {
			@include flex(0 1 auto);
			min-width: 18rem;
			width: 18rem;
			max-width: 18rem;
			border-right: 1px solid $color_mercury;
			//overflow-y: auto;
		}

		.counts {
			@include flexbox();
			height: 4.75rem;
			border-bottom: 1px solid $color_mercury;

			.messages,
			.unread {
				@include flex(1 1 50%);
				min-width: 50%;
				width: 50%;
				max-width: 50%;
				padding: 1rem;
				text-align: center;

				.count {
					@include flexbox();
					@include justify-content(center);
					@include align-items(center);
					width: 1.0625rem;
					height: 1.0625rem;
					margin: 0 auto .125rem auto;
					color: $color_white;
					font-size: .5rem;
					border-radius: 50%;
				}

				.count + span {
					font-weight: 600;
					font-size: .875rem;
					letter-spacing: .025em;
				}
			}

			.messages {
				color: $color_sushi;
				border-right: 1px solid $color_mercury;

				.count {
					background-color: $color_sushi;
				}
			}

			.unread {
				color: $color_carrot_orange;

				.count {
					background-color: $color_carrot_orange;
				}
			}
		}

		.thread_list {
			position: relative;

			@media (min-width: $message_center_to_desktop) {
				height: calc(100% - 4.75rem);
			}

			.toggle_threads_wrapper {
				@include flexbox();
				@include justify-content(center);
				@include align-items(center);
				padding: .75rem 1rem;
				color: $color_mine_shaft;
				background-color: $color_alabaster2;
				border-bottom: 1px solid $color_mercury;

				svg {
					margin-left: 1rem;
					@include transition(.25s all);

					path {
						fill: $color_mine_shaft;
					}
				}

				&.on svg {
					@include transform(rotate(180deg));
				}

				@media (min-width: $message_center_to_desktop) {
					display: none;
				}
			}

			.threads_wrapper {
				display: none;
				position: absolute;
				top: 100%;
				right: 0;
				left: 0;
				max-height: 12rem;
				background-color: $color_white;
				border-bottom: 1px solid $color_mercury;
				@include box-shadow(0 .25rem .5rem rgba($color_black, .1));
				overflow-y: auto;

				@media (min-width: $message_center_to_desktop) {
					position: relative;
					top: auto;
					right: auto;
					left: auto;
					display: block !important;
					height: 100%;
					max-height: none;
					border-bottom: 0;
					@include box-shadow(none);
				}

				.thread {
					position: relative;
					border-bottom: 1px solid $color_mercury;
					cursor: pointer;

					header {
						padding: .75rem .75rem .25rem .75rem;
						color: $color_mine_shaft;
						font-size: .875rem;
					}

					header + div {
						@include flexbox();
						@include justify-content(space-between);
						padding: 0 .75rem .75rem .75rem;
						color: $color_dusty_gray;
						font-size: .75rem;

						span:nth-of-type(2) {
							margin-left: 3rem;
						}
					}

					&.unread:after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 0;
						height: 0;
						border-top: 7.625px solid $color_carrot_orange;
						border-right: 7.625px solid transparent;
						border-bottom: 7.625px solid transparent;
						border-left: 7.625px solid $color_carrot_orange;
					}

					&.selected {
						background-color: $color_alabaster2;

						header {
							font-weight: bold;
						}

						header + div {
							color: $color_mine_shaft;
						}
					}
				}
			}
		}
	}

	.message_content {
		@include flex(1 1 auto);
		@include flexbox();
		@include flex-direction(column);

		.top {
			@include flex(1 0 auto);
			@include flexbox();
			border-bottom: 1px solid $color_mercury;

			.trash {
				@include flex(0 0 auto);
				@include flexbox();
				@include justify-content(center);
				@include align-items(center);
				width: 3.5rem;
				height: 3.5rem;
				border-right: 1px solid $color_mercury;
			}

			.title {
				@include flex(1 1 auto);
				@include flexbox();
				//@include justify-content(center);
				@include align-items(center);
				padding: 0 1rem;
			}

			.compose {
				@include flex(0 0 auto);
				@include flexbox();
				@include justify-content(center);
				@include align-items(center);
				width: 3.5rem;
				height: 3.5rem;
				border-left: 1px solid $color_mercury;
			}
		}

		.content {
			@include flex(1 1 auto);
			height: 38rem;
			padding: 1.5rem;
			border-bottom: 1px solid $color_mercury;
			overflow-y: scroll;

			.rd-ng-loader {
				height: 100%;
				@include flexbox();
				@include justify-content(center);
				@include align-items(center);
				@include flex(1 1 auto);
			}

			@media (min-width: $message_center_to_desktop) {
				height: 100%;
			}

			.no_content {
				position: relative;
				@include flexbox();
				@include flex-direction(column);
				@include justify-content(center);
				@include flex-direction(column);
				@include align-items(center);
				height: 100%;
				padding: 1.5rem;
				color: $color_dove_gray;
				font-size: .875rem;

				svg {
					margin-bottom: .25rem;
				}

				&:hover,
				&:focus {
					color: $color_carrot_orange;
				}
			}

			.message {
				clear: both;
				width: 80%;
				margin-bottom: 1.5rem;

				p {
					margin: 0;
					@include scale(padding, 1rem, 20rem, 1.5rem, 40rem);
					@include scale(padding, 1rem, 40rem, 1.5rem, 60rem);
					@include scale(font-size, .75rem, 20rem, 1rem, 40rem);
					@include scale(font-size, .75rem, 40rem, 1rem, 60rem);
					border-radius: 8px;
				}

				&.self {
					float: right;

					p {
						color: $color_white;
						background-color: $color_waterloo;
					}
				}

				&.php {
					float: left;

					p {
						color: $color_slate_gray;
						background-color: $color_aqua_haze;
					}
				}

				time {
					color: $color_gray;
					font-size: .875rem;
				}

				a:last-of-type {
					display: none;
					float: right;
					font-size: .875rem;
				}

				&:hover {
					a:last-of-type {
						display: inline;
					}
				}
			}
		}

		.bottom {
			@include flex(1 0 auto);
			@include flexbox();
			//border-bottom: 1px solid $color_mercury;

			.attach {
				@include flex(0 0 auto);
				@include flexbox();
				@include justify-content(center);
				@include align-items(center);
				width: 3.5rem;
				height: 3.5rem;
			}

			input {
				@include flex(1 1 auto);
				@include flexbox();
				@include justify-content(center);
				@include align-items(center);
				border: 0;
				margin-left: .5rem;

				&:focus-visible {
					outline: 1px solid $color_carrot_orange;
				}
			}


			.send {
				@include flex(0 0 auto);
				@include flexbox();
				@include justify-content(center);
				@include align-items(center);
				width: 3.5rem;
				height: 3.5rem;
			}
		}
	}
}

.message_center_compose_modal {
	width: 100% !important;
	max-width: 40.5rem !important;
	margin: 1.5rem auto;
	background-color: transparent !important;
	overflow: hidden !important;

	> div {
		background-color: $color_white;
		border-radius: 8px;
		@include box-shadow(0 6px 10px rgba($color_black, .18));

		header {
			@include flexbox();
			@include justify-content(space-between);
			@include align-items(center);
			padding: 0;
			color: $color_comet;
			font-size: 1.25rem;
			line-height: 3.75rem;
			text-align: center;
			border-bottom: 1px solid $color_alto;

			span {
				padding-left: 1rem;
			}

			a {
				padding: 1rem;

				svg {
					display: block;

					path {
						fill: $color_carrot_orange;
						@include transition(.25s all);
					}
				}

				&:hover,
				&:focus {
					svg path {
						fill: $color_comet;
					}
				}
			}
		}

		.form {
			margin: 0;
			padding: 0;

			> div {
				padding: 1.5rem 2rem 0 2rem;

				label {
					span {
						color: $color_comet;
						font-size: .9375rem;
					}

					input,
					textarea {
						border-radius: 4px;
						width: 100%;
						padding: .6875rem .875rem;
						color: $color_dove_gray;
						font-family: inherit;
						line-height: 1.5;
						border: 1px solid #ccc;
					}

					textarea {
						height: 7.5rem;
					}
				}
			}

			button {
				float: none;
				width: 100%;
				margin-top: 0;
				padding: .8125rem 1.5rem;
				color: $color_white;
				font-size: 1.25rem;
				border-radius: 0 0 8px 8px;
			}
		}
	}
}

.member_totals_message_center {
	$member_totals_message_center_breakpoint_to_desktop: 70em;

	@include flexbox();
	@include flex-direction(column);
	margin-bottom: 1rem;
	padding: 1.5rem 0;

	@media (min-width: $member_totals_message_center_breakpoint_to_desktop) {
		@include flex-direction(row);
		//@include align-items(flex-start);
		max-width: 80rem;
		margin-right: auto;
		margin-left: auto;
	}

	.message_center {
		@include flex(1 1 auto);
		@include flexbox();
		@include flex-direction(column);
		background-color: $color_white;
		border: 1px solid $color_alto;
		border-radius: 8px;

		@media (min-width: $member_totals_message_center_breakpoint_to_desktop) {
			max-width: 40%;
		}

		> div:nth-of-type(1) {
			@include flex(0 0 auto);
			@include flexbox();
			@include justify-content(space-between);
			padding: .75rem 1rem .75rem .25rem;
			border-bottom: 1px solid $color_alto;

			.back {
				padding: .5rem;

				svg {
					@include transform(rotate(90deg));
				}
			}

			header {
				padding-left: .75rem;
			}

			svg {
				display: block;
			}
		}

		.subject {
			@include flex(0 0 auto);
			padding: 0 1rem;
			color: $color_white;
			line-height: 2.25rem;
			text-align: center;
			background-color: $color_nevada;
		}

		.messages {
			@include flex(1 1 auto);
			position: relative;
			min-height: 12rem;
			max-height: 18rem;
			padding: 1rem;
			overflow-y: scroll;

			&.threads {
				padding: 0;
			}

			.no_messages {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				@include flexbox();
				@include flex-direction(column);
				@include justify-content(center);
				@include align-items(center);
				padding: 1.5rem;
				color: $color_dove_gray;
				font-size: .875rem;

				svg {
					margin-bottom: .25rem;
				}

				&:hover,
				&:focus {
					color: $color_carrot_orange;
				}
			}

			.message {
				header {
					.name {
						font-weight: 600;
						font-size: .875rem;
					}

					.timestamp {
						color: $color_silver;
						font-size: .75rem;
					}
				}

				p {
					margin-top: 0;
					font-size: .875rem;
					line-height: 1.21429;
				}
			}

			.thread {
				position: relative;
				border-bottom: 1px solid $color_mercury;
				cursor: pointer;
				@include transition(.25s all);

				&:hover,
				&:focus {
					background-color: $color_alabaster2;
				}

				header {
					padding: .75rem .75rem .25rem .75rem;
					color: $color_mine_shaft;
					font-size: .875rem;
				}

				header + div {
					@include flexbox();
					@include justify-content(space-between);
					padding: 0 .75rem .75rem .75rem;
					color: $color_dusty_gray;
					font-size: .75rem;

					span:nth-of-type(2) {
						margin-left: 3rem;
					}
				}

				&.unread:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 0;
					border-top: 7.625px solid $color_carrot_orange;
					border-right: 7.625px solid transparent;
					border-bottom: 7.625px solid transparent;
					border-left: 7.625px solid $color_carrot_orange;
				}

				&.selected {
					background-color: $color_alabaster2;

					header {
						font-weight: bold;
					}

					header + div {
						color: $color_mine_shaft;
					}
				}
			}
		}

		form {
			@include flex(0 0 auto);
			@include flexbox();
			@include align-items(center);
			margin: 0;
			padding: 0;
			border-top: 1px solid $color_alto;

			&:empty {
				border-top: 0;
			}

			a {
				@include flex(0 0 auto);
				padding: .5rem;

				svg {
					display: block;
				}
			}

			input {
				@include flex(1 1 auto);
				border: 0;
				border-radius: 0 0 0 8px;
			}
		}
	}
}
