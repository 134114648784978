.brochure-container {
	margin: 3rem auto;

	@media (min-width: 70em) {
		display: flex;
	}

	.sidebar {
		margin-bottom: 3rem;

		@media (min-width: 70em) {
			flex: 1 1 auto;
			min-width: 18.75rem;
			max-width: 18.75rem;
			margin-right: 2rem;
			margin-bottom: 0;
		}

		.my-brochure {

			header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 1rem;
				color: white;
				font-weight: 700;
				font-size: .875rem;
				letter-spacing: .103571428571429em;
				text-transform: uppercase;
				background-color: #453f6b;
				border-radius: 8px 8px 0 0;
			}

			.brochures {
				font-size: .875rem;
				border: 1px solid #ccc;
				border-top: 0;

				> a {
					flex: 0 0 auto;
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: white;
					background-color: $color_primary_green;
					text-transform: uppercase;
					font-weight: 700;
					padding: 1rem;
					font-size: .875rem;
					letter-spacing: .055714285714286em;

					svg {
						display: block;
						width: .875rem;
						height: .875rem;
					}

				}

				.headings {
					max-height: 12rem;
					overflow-y: auto;

					a {
						flex: 0 0 auto;
						display: flex;
						align-items: center;
						justify-content: space-between;
						color: #717171;
						padding: 1rem;
						font-weight: 400;
						font-size: .875rem;
						letter-spacing: .055714285714286em;
						border-left: 1px solid #d7d7d7;

						svg {
							display: block;
							width: .875rem;
							height: .875rem;
						}

						&:not(:last-of-type) {
							border-bottom: 1px solid #ccc;
						}

						&:hover,
						&:focus {
							color: $color_secondary_orange;
						}

						&.selected {
							color: white;
							font-weight: 700;
							background-color: $color_secondary_orange;
						}
					}
				}
			}
		}

		> .button {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: .625rem 1rem;
			font-size: .875rem;

			+ .button {
				margin-top: 1.5rem;
			}

			&.download {
				color: white;
				background-color: $color_primary_green;
				border-color: $color_primary_green;

				&:not(.disabled) {
					&:hover,
					&:focus {
						background-color: darken($color_primary_green, 15);
						border-color: darken($color_primary_green, 15);
					}
				}

				&.disabled {
					cursor: not-allowed;
					opacity: 0.5;
				}
			}

			&.new {
				margin: 0;
				margin-bottom: 1rem;
				color: white;
				background-color: $color_primary_green;
				border-color: $color_primary_green;


				&:hover,
				&:focus {
					background-color: darken($color_primary_green, 15);
					border-color: darken($color_primary_green, 15);
				}

				svg {
					display: block;
					width: .875rem;
					height: .875rem;
					transform: rotate(45deg);

					path {
						fill: currentColor;

					}
				}
				&.duplicate {
					margin-top: 1rem;
					svg {
						transform: initial;
						width: 1rem;
						height: 1rem;
						path {
							fill: currentColor;
						}
						g {
							stroke-width: 0;
						}
					}
				}
			}

			&.print {
				color: #453f6b;
				background-color: white;
				border: 2px solid #453f6b;

				svg {
					display: block;

					path {
						fill: currentColor;
					}
				}

				&:not(.disabled) {
					&:hover,
					&:focus {
						color: white;
						background-color: #453f6b;
					}
				}

				&.disabled {
					cursor: not-allowed;
					opacity: 0.5;
				}
			}
		}

		.report-order {
			margin-top: 2rem;

			header {
				margin-bottom: .75rem;
				color: #979a9d;
				font-weight: 700;
				font-size: .875rem;
				letter-spacing: .078571428571429em;
				text-transform: uppercase;

				small {
					font-size: .75rem;
				}
			}

			.reports {

				padding-bottom: 2rem;

				.report {
					display: flex;
					align-items: center;
					width: 100%;
					margin-top: 0;
					padding: .75rem;
					color: #6a737b;
					font-weight: 400;
					font-size: .875rem;
					text-transform: none;
					background-color: transparent;
					border: 1px solid #d7d7d7;
					border-radius: 8px;
					outline: none;
					margin-bottom: .75rem !important;

					svg {
						display: block;
						margin-right: .75rem;

						path {
							transition: all .25s;
						}
					}

					&:hover {
						cursor: grab;
					}

					&:focus {
						cursor: grabbing;
						box-shadow: 0 4px 4px rgba(black, .18);

						svg path {
							fill: $color_secondary_orange;
						}
					}
				}

				.dndDraggingSource {
					display: none !important;
				}

				.dndPlaceholder {
					min-height: 3rem;
					background-color: #ddd;
					border-radius: 0.5rem;
					margin-bottom: 0.75rem;
					list-style: none;
					list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); // Fix IE/Edge
				}
			}
		}
	}

	.main {
		flex: 1 1 auto;
		width: 100%;
		max-width: 100%;

		@media (min-width: 70em) {
			width: calc(100% - 18.75rem);
			max-width: calc(100% - 18.75rem);
		}

		.info {
			border: 1px solid #d7d7d7;
			border-radius: 8px;

			> header {
				display: flex;
				border-bottom: 1px solid #d7d7d7;

				span {
					flex: 1 1 auto;
					display: flex;
					align-items: center;
					padding: .75rem 1rem;
					font-size: 1.25rem;
					line-height: 1.35;
				}

				a {
					flex: 0 0 auto;
					display: flex;
					align-items: center;
					padding: 1rem;
					color: #6e6e6e;
					font-weight: 400;
					font-size: .875rem;
					letter-spacing: .055714285714286em;
					border-left: 1px solid #d7d7d7;

					svg {
						display: block;
						width: .875rem;
						height: .875rem;
					}

					&.reset svg {
						margin-right: .5rem;

						path {
							fill: $color_secondary_orange;
						}
					}

					&:hover,
					&:focus {
						color: $color_secondary_orange;
					}
				}

				.has-dropdown {
					position: relative;

					> a {
						height: 100%;
						svg {
							width: 28px;
						}
					}

					> div {
						position: absolute;
						top: 100%;
						right: 0;
						display: none;
						max-width: 18rem;
						background-color: white;
						border: 1px solid $color_table_borders;
						border-radius: 5px;
						box-shadow: 0 3px 5px rgba(black, .5);
						z-index: 3;

						ul {
							list-style: none;
							margin: 0;
							padding: 0;

							li {

								&:not(:last-of-type) {
									border-bottom: 1px solid $color_table_borders;
								}

								a {
									display: flex;
									align-items: center;
									padding: .5rem 1rem;
									font-size: 1rem;
									white-space: nowrap;
									border-left: 0;
									height: 44px;

									.svg,
									svg {
										flex: 0 0 1.5rem;
										margin-right: .75rem;
										margin-left: 0;
										width: 18px;
										height: 18px;

										svg path {
											fill: currentColor;
										}


									}
								}

								&:hover {
									a {
										svg  {
											path {
												fill: $color_carrot_orange;
											}
										}
									}

								}
							}
						}
					}
				}
			}

			.section {
				margin: 1rem 1.5rem 2rem;

				header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: .5rem;
					border-bottom: 1px solid #d7d7d7;

					span {
						color: #746e96;
						font-size: 1.25rem;
						letter-spacing: .037em;
					}

					a {
						display: flex;
						align-items: center;
						color: #6e6e6e;
						font-size: .625rem;
						text-transform: uppercase;

						svg {
							display: block;
							margin-right: .5rem;
						}

						&:hover,
						&:focus {
							color: $color_bittersweet;
						}
					}
				}

				p {
					position: relative;
					max-height: 6.25rem;
					font-size: .875rem;
					line-height: 1.785714285714286;
					overflow: hidden;

					&::after {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						@include scrim-gradient(to top, white);
						z-index: 1;
					}
				}

				.brochure-builder-pdf-preview {

					padding: 1rem;
					display: flex;

					svg {
						flex-shrink: 0;

						path:first-of-type {
							fill: $color_comet;
						}
					}

					a{
						margin-left: 1rem;
					}
				}
			}

			.empty {

				p {
					padding: 1rem;
				}

			}
		}

		.footer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 1rem;
			color: #959597;
			font-size: .875rem;
			letter-spacing: .055714285714286em;

			time::before {
				content: 'Last Updated:\00a0';
			}

			a {
				display: flex;
				align-items: center;
				color: #6e6e6e;
				font-weight: 600;

				svg {
					display: block;
					margin-right: .5rem;
				}

				&:hover,
				&:focus {
					color: $color_bittersweet;
				}
			}
		}
	}
}


.brochure-builder-prepare {

	.logo-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 2rem;

		.logo {
			display: inline-block;
			height: 200px;

			div {
				position: relative;
				display: inline-block;
			}

			img {
				height: 100%;
				object-fit: contain;
				max-height: 300px;
			}
			.delete {
				cursor: pointer;
				svg {
					path:first-of-type {
						fill: rgba(black, .7);
					}
				}
				position: absolute;
				top: -15px;
				right: -15px;
				width: 30px;
				height: 30px;

				&:focus, &:hover {

					svg {
						path:first-of-type {
							fill: rgba(black, 1);
						}
					}
				}
			}
		}

	}

	.uploader {

		h1 {
			margin-bottom: 0;
		}

		.drop {
			display: flex;
			flex-direction: column;
			font-size: 1.25rem;
			background-color: #c8dadf;
			// fill: #ff7400;
			position: relative;
			padding: 14px  20px;
			margin-top: 2rem;
			max-width: 500px;
			cursor: pointer;
			@media only screen and (min-width: 800px) {
				width: 500px;
			}

			.drop__icon {
			  display: block;

				svg {
					width: 100%;
					height: 4rem;

					path {
						fill: $color_comet;
					}

				}
			}

			.drop__text {
				margin-top: 0.5rem;
				text-align: center;

				strong {
					color: $color_carrot_orange;
				}

				.or {
					text-transform: uppercase;
					font-weight: 700;
					font-size: 1.0rem;
				}

				.drag {
					color: $color_comet;
					font-weight: 700;
				}

				&.drop__text-error {
					color: #c7031e;
				}
			}

			strong {
				text-decoration: underline;
			}

			&.has-advanced-upload {
				// outline: 2px dashed #dcdcdc;
				// outline-offset: -10px;
				// transition: outline-offset .15s ease-in-out, background-color .15s linear;

				box-shadow: 0 0 0 1pt #ccc;
				border-radius: 4px;
				max-width: 200px;
				margin: 0 auto;

				background-color: white;

				&.drop__dragndrop {
					display: inline;
				}

				&.dragover {
					background-color: rgba(#0f5ca2, .2);
					outline: 2px dashed white;
					color: white;

					.drop__icon {
						fill: white;
					}
				}

				&.dragover-err {
					background-color: rgba(#c7031e, .8);
					outline: 2px dashed white;
					color: white;

					.drop__icon {
						fill: white;
					}
				}
			}
			&.dragover {
				background-color: rgba(#0f5ca2, .2);
			}

			&.dragover-err {
				background-color: rgba(#c7031e, .2);
			}
		}
	}
}
