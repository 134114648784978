// https://codegeekz.com/best-css-svg-loaders-and-spinners/#9
$rdLoaderFullScreenZindex: 1000;

@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes spin {
	to {
		-moz-transform: rotate(360deg);
	}
}

@-ms-keyframes spin {
	to {
		-ms-transform: rotate(360deg);
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.rd-ng-loader-container {
    width: 100%;
    .rd-ng-loader {
        display:flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        margin: 0 auto;
        margin: 0;
        text-align: center;
        font-size: 0;
        svg {
            width: 80px;
            height: auto;
            fill: $color_comet;
            stroke: $color_comet;
            animation: spin 1s infinite linear;
            -webkit-animation: spin 1s infinite linear;
        }
        img {
            object-fit: contain;
        }
    }

    p {
        display:flex;
        justify-content: center;
        text-transform: uppercase;
        color: rgba($color_regent_gray, 0.8);
        margin-top: 1rem;
        font-size: 0.8rem;
    }

    &.full-screen {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 8%;
        width: 100%;
        height: 100%;
        z-index: $rdLoaderFullScreenZindex;
        p {
            color: white;
            font-weight: 800;
            font-size: 1.2rem;
        }
        .rd-ng-loader {
            align-self: center;
            svg {
                fill: white;
                stroke: white;
            }
        }

        &.rd-ng-loader--theme-light {
            p {
                color: #575757;
            }
        }
    }

    &.rd-ng-loader--icon {
        display: flex;
        justify-content: center;
        .rd-ng-loader {
            position: relative;
            padding: 0;
            height: 0;
        }
        svg {
            height: 100%;
            width: 100%;
            fill: white;
            stroke: white;
        }
        &:hover {
            svg {
                fill: white;
                stroke: white;
            }
        }
    }
}

button:hover[type=submit] {
    .rd-ng-loader-container {
        &.rd-ng-loader--icon {
            .rd-ng-loader {
                svg {
                    fill: white;
                    stroke: white;
                }
            }
        }
    }
}

button[type=submit] {
    .rd-ng-loader-container {
        &.rd-ng-loader--icon {
            .rd-ng-loader {
                svg {
                    fill: white;
                    stroke: white;
                }
            }
        }
    }
}

.rd-ng-loader-container-background {
    display: none;
    &.full-screen {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, 0.33);
        z-index: $rdLoaderFullScreenZindex - 1;

        &.rd-ng-loader--theme-light {
            background-color: rgba(white, 0.6);
        }
    }

}


span.green {
    .rd-ng-loader-container {
        &.rd-ng-loader--icon {
            .rd-ng-loader {
                svg {
                    fill: $color_mantis;
                    stroke: $color_mantis;
                }
            }
        }
    }
}

span.orange {
    .rd-ng-loader-container {
        &.rd-ng-loader--icon {
            .rd-ng-loader {
                svg {
                    fill: $color_carrot_orange;
                    stroke: $color_carrot_orange;
                }
            }
        }
    }
}
