.swal-footer {
    text-align: center;
}

.swal-button, .swal-button--confirm {
    background-color: $color_secondary_orange !important;

    &:focus {
        background-color: $color_secondary_orange !important;
    }

    &:hover {
        background-color: darken($color_secondary_orange, 12) !important;
    }

    &:not([disabled]):hover, &:not([disabled]):focus {
        background-color: $color_secondary_orange !important;
    }

}

.swal-button--danger {
    background-color: $color_monza !important;

    &:focus {
        background-color: $color_monza !important;
    }

    &:hover {
        background-color: darken($color_monza, 12) !important;
    }

    &:not([disabled]):hover, &:not([disabled]):focus {
        background-color: darken($color_monza, 12) !important;
    }
}

.swal-button--cancel {
    background-color: $color_dusty_gray !important;

    &:focus {
        background-color: $color_dusty_gray !important;
    }

    &:hover {
        background-color: darken($color_dusty_gray, 12) !important;
    }

    &:not([disabled]):hover, &:not([disabled]):focus {
        background-color: darken($color_dusty_gray, 12) !important;
    }
}

.swal-button {
    &:focus {
        box-shadow:none;
    }
}

.swal-content {
    .centered-content {
        text-align: center;
        padding-top: 2rem;
    }

    .centered-content ul {
        text-align: left;
        list-style-position: inside;
        display: inline-block;
    }
}

body.is-touch-device {
    .swal-modal {
        .swal-footer {
            display: flex;
            flex: 1 1 100%;
            justify-content: space-between;

            .swal-button-container {
                min-width: 0;
                margin: 0;
                flex: 1 1 100%;
                display: flex;

                + .swal-button-container {
                margin-left: 0.5rem;
                }

                button {
                    min-width: 0;
                    margin: 0;
                    display: block;
                    flex: 1 1 100%;
                }
            }
        }
    }
}
