.cke_editable {
	position: relative;
	left: calc(50% - 1.5rem);
	max-width: 60rem;
	min-height: 0 !important;
	height: auto !important;
	margin: 1.5rem;
	transform: translateX(-50%);

	img {
		display: block;
		width: 90% !important;
		height: auto !important;
		margin: 2em auto;
	}
}