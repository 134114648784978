label.select,
label.dynamic-recipients {
  .field-wrapper.chevron {
    &:before {
      content: url('../../../svgs/font-awesome/regular/chevron-down.svg');
      position: absolute;
      top: 50%;
      right: .875rem;
      z-index: 1;
      transform: translateY(-50%);
    }
  }

  .field-wrapper {
    select {
      appearance: none;
      position: relative;
      width: 100%;
      padding: .6875rem 2.625rem .6875rem .875rem;
      color: $color_input_text;
      font-family: inherit;
      line-height: 1.5;
      background-color: transparent;
      border: $input_border_thickness solid $color_input_border;
      border-radius: $input_border_radius;
      z-index: 2;
      transition: all .25s;

      &::-ms-expand {
        display: none;
      }

      &::placeholder {
        color: $color_input_placeholder;
        font: inherit;
      }

      &:focus {
        border-color: $color_carrot_orange;
      }


    }

    &:after {
      content: url('../../../svgs/chevron_down.svg');
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      right: .875rem;
    }

    &.error {
      &:before {
        right: 3.25rem;
      }

      select {
        padding-right: 5.125rem;
        border-color: $color_form_errors;
      }
    }

    &.multi-select::after {
      content: none;
    }
  }
}