.dashboard.provider-fee-lookup {
    display: block;

    thead.header {
        tr {

            th {
                background-color: $color_secondary_purple;
                padding: 1rem !important;
                font-size: 1.2rem;
                color: $color_white;
                //border-color: $color_primary_purple;

            }
        }
    }

    .title {
        background-color: $color_white;
        font-size: 1.2rem;
        padding: 2rem;
        font-weight: 400;
        margin: 0;
        text-align: center;
        border-bottom: 1px solid black;

        p {
            color: $color_secondary_purple;
            margin:0;
            &:first-child {
                margin-bottom: 1rem;
            }
        }
        
    }
    table {
        width: 100%;

        th.flex {

        }
    }
}