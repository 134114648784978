.pagination-list {
	$breakpoint_to_single_row : 36em;
	position: relative;
	display: flex;
	list-style: none;
	margin: 4rem 0 1rem;
	padding: 0;

	@media (min-width: $breakpoint_to_single_row) {
		margin: 1rem 0;
	}

	li {
		flex: 1 1 auto;
		margin-bottom: 0 !important;
		text-align: center;
		border: 1px solid $color_alto;
		border-left-width: 0;

		&:nth-of-type(2) {
			border-left-width: 1px;
			border-radius: 0 0 0 4px;
		}

		&:nth-last-of-type(2) {
			border-radius: 0 0 4px 0;
		}

		@media (min-width: $breakpoint_to_single_row) {
			flex: 0 1 auto;

			&:nth-of-type(2) {
				border-left-width: 0;
			}

			&:nth-of-type(2),
			&:nth-last-of-type(2) {
				border-radius: 0;
			}
		}

		a {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			padding: .625rem .75rem;
			color: $color_body;
			@include scale(font-size, 1rem, 20em, 1.25rem, $breakpoint_to_single_row);
			text-decoration: none;
			cursor: pointer;

			@media (min-width: $breakpoint_to_single_row) {
				padding: .625rem 1.375rem;
			}

			svg {
				display: block;
				width: 1.25rem;
				height: .87rem;

				path {
					fill: $color_secondary_orange;
					transition: all .25s;
				}
			}

			&.active,
			&:hover,
			&:focus {
				color: $color_secondary_purple;
				font-weight: 700;
				background-color: #eee;
			}
		}

		> svg {
			display: block;
			width: 1.25rem;
			@include scale(margin-top, 1.125rem, 20em, 1.375rem, $breakpoint_to_single_row);
			margin-right: auto;
			@include scale(margin-bottom, 1.125rem, 20em, 1.375rem, $breakpoint_to_single_row);
			margin-left: auto;

			@media (min-width: $breakpoint_to_single_row) {
				margin: 1.375rem 1.125rem;
			}
		}

		&.first {
			position: absolute;
			right: 50%;
			bottom: 100%;
			left: 0;
			border-bottom-width: 0;
			border-left-width: 1px;
			border-radius: 4px 0 0 0;

			@media (min-width: $breakpoint_to_single_row) {
				position: relative;
				right: auto;
				bottom: auto;
				left: auto;
				border-bottom-width: 1px;
				border-radius: 4px 0 0 4px;
			}
		}

		&.last {
			position: absolute;
			right: 0;
			bottom: 100%;
			left: 50%;
			border-bottom-width: 0;
			border-radius: 0 4px 0 0;

			@media (min-width: $breakpoint_to_single_row) {
				position: relative;
				right: auto;
				bottom: auto;
				left: auto;
				border-bottom-width: 1px;
				border-radius: 0 4px 4px 0;
			}

			a {
				svg {
					transform-origin: 50% 50%;
					transform: rotate(180deg);
				}
			}
		}

		&.first,
		&.last {
			a {
				width: 100%;
				padding: 1.125rem 1.125rem 1rem;

				@media (min-width: $breakpoint_to_single_row) {
					padding: 1.25rem 1.125rem 1.3125rem;
				}

				&.disabled {
					pointer-events: none;

					svg path {
						fill: darkgray;
					}
				}
			}
		}
	}
}

.results-pagination {
	.results {
		.field-wrapper {
			position: relative;
			display: block;
			margin-bottom: .5rem;

			@media (min-width: 36em) {
				display: inline-block;
				margin-right: 1rem;
				margin-bottom: 0;
			}

			@media (min-width: $breakpoint_to_full_sidebar) {
				display: block;
				margin-right: 0;
				margin-bottom: .5rem;
			}

			@media (min-width: 74em) {
				display: inline-block;
				margin-right: 1rem;
				margin-bottom: 0;
			}

			&::before {
				content: url('../../svgs/select-dropdown-expand.svg');
				position: absolute;
				top: 50%;
				right: .875rem;
				width: .625rem;
				height: 1.375rem;
				z-index: 1;
				transform: translateY(-50%);
			}

			.per-page {
				appearance: none;
				position: relative;
				width: 100%;
				padding: .6875rem 2.625rem .6875rem .875rem;
				color: $color_input_text;
				font-family: inherit;
				line-height: 1.5;
				background-color: transparent;
				border: $input_border_thickness solid $color_input_border;
				border-radius: $input_border_radius;
				z-index: 2;
				transition: all .25s;

				&::-ms-expand {
					display: none;
				}

				&::placeholder {
					color: $color_input_placeholder;
					font: inherit;
				}

				&:focus {
					border-color: $color_input_border_focus;
					outline: none;
				}
			}
		}
	}
}