.media-gallery {
	$breakpoint_first : 40em;
	$breakpoint_second : 60em;
	@extend %clearfix;

	> div {
		margin-bottom: 22px;

		&:nth-of-type(10n + 1) {
			float: left;
			width: 100%;

			@media (min-width: $breakpoint_first) {
				width: calc((100% - 22px) / 3.0001 * 2);
				margin-right: 22px;
			}

			@media (min-width: $breakpoint_second) {
				width: calc((100% - 44px) / 5.0001 * 3);
			}

			figure {
				padding-bottom: 56.25%;

				@media (min-width: $breakpoint_first) {
					padding-bottom: calc(75% + 22px);
				}

				@media (min-width: $breakpoint_second) {
					padding-bottom: calc(50% + 22px);
				}
			}
		}

		&:nth-of-type(10n + 2) {
			float: left;
			width: calc((100% - 22px) / 2.0001);
			margin-right: 22px;

			@media (min-width: $breakpoint_first) {
				width: calc((100% - 22px) / 3.0001);
				margin-right: 0;
			}

			@media (min-width: $breakpoint_second) {
				width: calc((100% - 44px) / 5.0001);
				margin-right: 22px;
			}

			figure {
				padding-bottom: 75%;
			}
		}

		&:nth-of-type(10n + 3) {
			float: left;
			width: calc((100% - 22px) / 2.0001);

			@media (min-width: $breakpoint_first) {
				width: calc((100% - 22px) / 3.0001);
				margin-right: 0;
			}

			@media (min-width: $breakpoint_second) {
				width: calc((100% - 44px) / 5.0001);
			}

			figure {
				padding-bottom: 75%;
			}
		}

		&:nth-of-type(10n + 4) {
			float: left;
			width: calc((100% - 22px) / 2.0001);
			margin-right: 22px;

			@media (min-width: $breakpoint_second) {
				width: calc((100% - 44px) / 5.0001);
				margin-right: 22px;
			}

			figure {
				padding-bottom: 75%;
			}
		}

		&:nth-of-type(10n + 5) {
			float: left;
			width: calc((100% - 22px) / 2.0001);

			@media (min-width: $breakpoint_second) {
				width: calc((100% - 44px) / 5.0001);
			}

			figure {
				padding-bottom: 75%;
			}
		}

		&:nth-of-type(10n + 6) {
			float: right;
			width: 100%;

			@media (min-width: $breakpoint_first) {
				width: calc((100% - 22px) / 3.0001 * 2);
				margin-left: 22px;
			}

			@media (min-width: $breakpoint_second) {
				width: calc((100% - 44px) / 5.0001 * 3);
			}

			figure {
				padding-bottom: 56.25%;

				@media (min-width: $breakpoint_first) {
					padding-bottom: calc(75% + 22px);
				}

				@media (min-width: $breakpoint_second) {
					padding-bottom: calc(50% + 22px);
				}
			}
		}

		&:nth-of-type(10n + 7) {
			float: right;
			width: calc((100% - 22px) / 2.0001);
			margin-left: 22px;

			@media (min-width: $breakpoint_first) {
				width: calc((100% - 22px) / 3.0001);
				margin-left: 0;
			}

			@media (min-width: $breakpoint_second) {
				width: calc((100% - 44px) / 5.0001);
				margin-left: 22px;
			}

			figure {
				padding-bottom: 75%;
			}
		}

		&:nth-of-type(10n + 8) {
			float: right;
			width: calc((100% - 22px) / 2.0001);

			@media (min-width: $breakpoint_first) {
				width: calc((100% - 22px) / 3.0001);
				margin-left: 0;
			}

			@media (min-width: $breakpoint_second) {
				width: calc((100% - 44px) / 5.0001);
			}

			figure {
				padding-bottom: 75%;
			}
		}

		&:nth-of-type(10n + 9) {
			float: right;
			width: calc((100% - 22px) / 2.0001);
			margin-left: 22px;

			@media (min-width: $breakpoint_second) {
				width: calc((100% - 44px) / 5.0001);
				margin-left: 22px;
			}

			figure {
				padding-bottom: 75%;
			}
		}

		&:nth-of-type(10n + 10) {
			float: right;
			width: calc((100% - 22px) / 2.0001);

			@media (min-width: $breakpoint_second) {
				width: calc((100% - 44px) / 5.0001);
			}

			figure {
				padding-bottom: 75%;
			}
		}
	}
}