.notification_list {
  margin-top: 3rem;

  .notification {
    border-bottom: 1px solid $color_silver;
    margin: 0 .75rem;
    padding: .75rem 0;

    ul, p {
      background-color: rgba(0, 0, 0, 0) !important;
    }

    .notif_title {
      position: relative;
      padding: .75rem 4rem .75rem 0;
      color: $color_scorpion;
      font-weight: 400;
      font-size: 1.0625rem;
      cursor: pointer;
      -webkit-transition: .2s all;
      transition: .2s all;

      &:hover {
        color: $color_carrot_orange;
        transition: .2s all;

        svg {
          fill: $color_carrot_orange;
          transition: .2s all;
        }

        .notif_date {
          color: $color_carrot_orange;
          transition: .2s all;
        }
      }

      .notif_date {
        color: $color_dusty_gray;
        display: block;
        font-size: .875rem;
      }

      .notif_unread {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 1rem;
        left: calc(100% - 7rem);
        width: 1rem;

        svg {
          path {
            fill: $color_mantis;
          }

          position: absolute;
          top: 50%;
          left: 50%;
          width: 1rem;
          height: 1rem;
        }
      }

      .notif_trash {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 1rem;
        left: calc(100% - 5rem);
        width: 1rem;

        svg {
          g {
            fill: $color_bittersweet;
          }

          position: absolute;
          top: 50%;
          left: 50%;
          width: 1rem;
          height: 1rem;
        }
      }

      .notif_toggle {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: calc(100% - 3rem);

        svg {
          fill: currentColor;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 1rem;
          height: 1rem;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          -webkit-transition: .2s all;
          transition: .2s all;
        }
      }

      &.on {
        color: $color_carrot_orange;

        .notif_date {
          color: $color_carrot_orange;
        }

        .notif_toggle svg {
          fill: $color_carrot_orange;
          -webkit-transform: translate(-50%, -50%) rotate(180deg);
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }

    .notif_answer {
      border: 0;
      clear: both;
      display: none;
      margin: 0;
      padding: 0 1.25rem 0 0;
    }
  }
}

.notif_none {
  text-align: center;
  font-weight: bold;
}

.notifications_dd_list {
  ul > li:not(.up_to_speed) {
    cursor: pointer;
  }
}