.progress_nav {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-top: 1px solid $color_alto;

	@media (min-width: 40em) {
		flex-wrap: nowrap;
	}

	& > a {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 1rem;
		color: inherit;
		line-height: 4.5rem;

		@media (min-width: 40em) {
			width: 10rem;
		}

		&:hover {
			color: $color_carrot_orange;
		}

		&:first-child {
			order: 2;
			flex: 1 0 50%;
			max-width: 50%;
			white-space: nowrap;
			border-right: 1px solid $color_alto;

			@media (min-width: 40em) {
				order: 1;
				flex: 0 1 auto;
			}
		}

		&:last-child {
			order: 3;
			flex: 1 0 50%;
			max-width: 50%;
			white-space: nowrap;

			@media (min-width: 40em) {
				flex: 0 1 auto;
				border-left: 1px solid $color_alto;
			}
		}
	}

	.progress {
		order: 1;
		flex: 1 0 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 4.5rem;
		border-bottom: 1px solid $color_alto;

		@media (min-width: 40em) {
			order: 2;
			flex: 1 1 auto;
			border-bottom: 0;
		}

		div {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 2.375rem;
			height: 2.375rem;
			margin: .625rem;
			border: 2px solid $color_silver_chalice;
			border-radius: 50%;

			svg {
				display: none;
			}

			span {
				font-weight: 700;
				font-size: .9375rem;
			}

			&.active {
				position: relative;
				border-color: $color_mantis;

				&:before {
					content: '';
					position: absolute;
					top: -1.25rem;
					left: 50%;
					width: .6875rem;
					height: .375rem;
					background: transparent url('../../svgs/toggle-select.svg') center no-repeat scroll;
					transform: translateX(-50%);
				}

				span {
					color: $color_mantis;
				}
			}

			&.complete {
				background-color: $color_mantis;
				border-color: $color_mantis;

				svg {
					display: block;
				}

				span.text {
					display: none;
				}
			}
		}
	}
}

body[data-contrast-mode=on] {
	.progress_nav {
		.progress div {
			border-color: $color_white;

			&.active,
			&.complete {
				border-color: $color_mantis;

				svg {
					fill: $color_black;
				}
			}
		}
	}
}
