abbr {
	position: relative;
	display: inline-block;
	border-bottom: 1px dashed $color_secondary_purple;
	cursor: pointer;
}

.tooltip {
	position: absolute;
	width: calc(100vw - 2rem);
	max-width: 21.25rem;
	color: $color_body;
	background-color: $color_white;
	border-bottom: .6875rem solid $color_secondary_purple;
	border-radius: 0;
	box-shadow: $drop_shadow;
	cursor: pointer;
	pointer-events: none;
	z-index: 1000 !important;

	&:before {
		top: 0 !important;
		right: 0 !important;
		padding: .5rem 1.125rem;
		color: $color_secondary_orange !important;
		font-size: 1.125rem !important;
		cursor: pointer;
		pointer-events: auto;
	}

	&:after {
		bottom: -1.25rem !important;
		border-top: 10px solid rgba($color_secondary_purple, 1) !important;
	}

	h2 {
		margin-top: .6875rem;
		margin-bottom: .5rem;
		padding: 0 1.25rem;
		font-size: 1.125rem;
		text-align: left;
		text-transform: capitalize;
	}

	p {
		margin-top: 0;
		margin-bottom: 1rem;
		padding: 0 1.25rem;
		font-size: .875rem;
		line-height: 1.5;
		text-align: left;
	}
}