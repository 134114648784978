.marker-info {
	position: relative;
	padding: 24px 40px;
	color: white;
	font-size: .875rem;
	text-align: center;
	background-color: $color_primary_purple;
	border-radius: 4px;

	&::after {
		content: '';
		position: absolute;
		width: 15px;
		height: 15px;
		top: calc(100% - 9px);
		left: 50%;
		background-color: $color_primary_purple;
		border-radius: 2px;
		transform-origin: 50% 50%;
		transform: translateX(-50%) rotate(45deg);
	}

	p {
		margin: .5em 0;

		&:first-of-type {
			margin-top: 0;
		}
	}

	.title {
		font-weight: 700;
	}

	a {
		color: $color_secondary_orange;
	}
}