form {
	padding: 2rem 0;

	*:focus {
		outline: none;
	}
}

.form-pages {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 0 3rem 0;
	padding: .6875rem 1rem .6875rem 2rem;
	color: $color_white;
	background-color: $color_form_pages_background;
	border-left: 10px solid darken($color_form_pages_background, 15%);

	li {
		padding: 0 .25rem;
		color: $color_form_pages_text;

		&:not(:last-of-type) {
			margin-right: 2.5rem;
		}

		&.active {
			font-weight: 700;
		}
	}
}

.form-success {
	margin-bottom: 3rem;

	header {
		display: flex;
		align-items: center;
		padding: .5rem 1rem .5rem 2rem;
		color: $color_white;
		background-color: $color_form_success;
		border-left: 10px solid darken($color_form_success, 15%);

		.message {
			padding-right: 1rem;
		}

		.check {
			margin-left: auto;
			padding-top: .25rem;

			svg {
				height: 1rem;
				width: 1rem;

				path {
					fill: $color_white;
				}
			}
		}
	}

	.submitted-fields {
		width: 100%;
		margin: 1rem 0;

		&:empty {
			display: none;
		}

		.field {
			padding: 1rem 0;
			border-bottom: 1px solid $color_gallery;

			label {
				margin-top: 0;
				margin-bottom: 0;
				font-weight: 700;
			}
		}
	}
}

.form-errors {
	margin-bottom: 3rem;

	header {
		display: flex;
		align-items: center;
		padding: .5rem 1rem .5rem 2rem;
		color: $color_white;
		background-color: $color_form_errors;
		border-left: 10px solid darken($color_form_errors, 15%);

		.message {
			padding-right: 1rem;
		}

		.times {
			margin-left: auto;
			padding-top: .25rem;

			svg {
				height: 1.25rem;
				width: 1.25rem;

				path {
					fill: $color_white;
				}
			}
		}
	}

	.errors {
		width: 100%;
		margin: 0 0 1rem 0;

		&:empty {
			display: none;
		}

		.error {
			padding: 1rem 0 1rem 1rem;
			color: $color_form_errors;
			border-bottom: 1px solid $color_gallery;
		}
	}
}

.form-row {
	margin: 1rem 0 2rem 0;
}

label {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 1rem;

	.field-label,
	.option-label {
		display: block;
		width: 100%;
		margin-bottom: .25rem;
		color: $color_secondary_purple;
		font-size: 1.125rem;
		font-family: $font_family_labels;
		letter-spacing: .056875em;

		small {
			font-size: .75rem;
			font-family: $font_family_body;
			letter-spacing: normal;

			&::before {
				content: "(";
			}

			&::after {
				content: ")";
			}
		}

		&.required:before {
			content: '*';
			padding: 0 .25rem;
			color: $color_form_errors;
			font-weight: 700;
		}
	}

	.field-instructions {
		display: block;
		width: 100%;
		margin-bottom: .25rem;
		font-size: .875rem;
		font-family: $font_family_instructions;
	}

	.field-wrapper {
		position: relative;

		&.error {
			&::after {
				content: '!';
				position: absolute;
				top: 50%;
				right: .75rem;
				width: 1.5rem;
				height: 1.5rem;
				color: $color_white;
				font-weight: 700;
				line-height: 1.5;
				text-align: center;
				background-color: $color_form_errors;
				border-radius: $input_border_radius;
				transform: translateY(-50%);
			}
		}

		&.complete {
			input,
			select,
			textarea {
				border-color: $color_input_border_complete !important;
			}
		}
	}

	.field-error {
		display: block;
		padding: .375rem;
		color: $color_form_errors;
		font-size: .875rem;

		&:empty {
			display: none;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}
}

label.text,
label.email,
label.password,
label.date,
label.time,
label.number,
label.phone,
label.url {
	.field-wrapper {
		input {
			width: 100%;
			padding: .5625rem .875rem;
			color: $color_input_text;
			font-family: inherit;
			line-height: inherit;
			border: $input_border_thickness solid $color_input_border;
			border-radius: $input_border_radius;
			transition: all .25s;

			&::placeholder {
				color: $color_input_placeholder;
				font: inherit;
			}

			&.focus,
			&:focus {
				border-color: $color_carrot_orange;
				outline: 0;
			}

			&[disabled] {
				border-color: $color_input_border_disabled;
				background-color: #eee;
				cursor: not-allowed;
			}
		}

		&.error input {
			padding-right: 3rem;
			border-color: $color_form_errors;
		}
	}
}

label.textarea {
	.field-wrapper {
		textarea {
			display: block;
			width: 100%;
			height: 10.625rem;
			padding: .6875rem .875rem;
			color: $color_input_text;
			font-family: inherit;
			line-height: inherit;
			border: $input_border_thickness solid $color_input_border;
			border-radius: $input_border_radius;
			transition: all .25s;

			&::placeholder {
				color: $color_input_placeholder;
				font: inherit;
			}

			&:focus {
				border-color: $color_carrot_orange;
				outline: 0;
			}
		}

		&.error textarea {
			padding-right: 3rem;
			border-color: $color_form_errors;
		}
	}
}

label.toggle {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	.label {
		color: #858585;
		font-size: 1.125rem;
		font-weight: 700;

		&.left {
			color: #242230;
		}
	}

	.bar {
		position: relative;
		width: 5.25rem;
		height: 1.875rem;
		margin: 0 1.5rem;
		background-color: #242230;
		border-radius: 100rem;

		.switch {
			position: absolute;
			top: 50%;
			left: -.625rem;
			width: 2.5rem;
			height: 2.5rem;
			background-color: white;
			border: 1px solid rgba(#717171, .15);
			border-radius: 50%;
			transition: all .25s;
			transform: translateY(-50%);

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transition: all .25s;
				transform: translate(-50%, -50%);

				&:first-of-type {
					opacity: 1;
				}

				&:last-of-type {
					opacity: 0;

					path {
						fill: #82c056;
					}
				}
			}
		}
	}

	input[type=checkbox] {
		position: absolute;
		opacity: 0;
		z-index: -1;

		&:checked {
			~ .label {
				&.left {
					color: #858585;
				}

				&.right {
					color: #82c056;
				}
			}

			~ .bar .switch {
				left: calc(100% - 1.875rem);

				svg {
					&:first-of-type {
						opacity: 0;
					}

					&:last-of-type {
						opacity: 1;
					}
				}
			}
		}
	}
}

@import 'forms/select';
@import 'forms/checkbox';
// @import 'forms/checkbox-group';
// @import 'forms/checkbox-group-option';
// @import 'forms/radio-group';
@import 'forms/radio-group-option';
// @import 'forms/file';
// @import 'forms/number';
@import 'forms/date';

// Google reCAPTCHA badge
.g-recaptcha {
	margin-bottom: 2rem;
}

input[type=submit],
button {
	@extend .button;
	line-height: inherit;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .25s;

}

.buttons {
	display: flex;
	flex-wrap: wrap;
	margin-right: -.5rem;
	margin-left: -.5rem;

	&.left {
		justify-content: flex-start;
	}

	&.center {
		justify-content: center;
	}

	&.right {
		justify-content: flex-end;
	}

	&.spread {
		justify-content: space-between;
	}

	input[type=submit],
	button {
		margin-right: .5rem;
		margin-bottom: 2rem;
		margin-left: .5rem;
	}
}

form {
	.field-note {
		margin-top: .25rem !important;
		color: $color_secondary_purple;
		font-size: .875rem;
		text-align: center !important;

		&.right {
			text-align: right !important;
		}
	}

	&:focus {
		outline: none;
	}

}

form {
	.success {
		font-style: italic;
		color: $color_mantis;

		a {
			color: $color_mantis;
		}
	}
}

label.checkbox {
	@include flexbox();
	cursor: pointer;

	&.centered {
		@include justify-content(center);
	}

	.checkbox {
		@include appearance(none);
		position: relative;
		display: inline-block;
		width: 1.5625rem;
		height: 1.5625rem;
		margin-right: 1.5rem;
		border: 1px solid $color_input_border;
		cursor: pointer;
		@include transition(.25s all);

		&:after {
			@include transition(.25s all);
		}

		+ span {
			position: relative;
			width: auto;
			display: inline-block;
			margin-bottom: 0;
			font-family: $font_family_body;
			line-height: 1.5625;
			letter-spacing: normal;
		}
	}

	input[type=checkbox] {
		position: absolute;
		opacity: 0;
		z-index: -1;

		&:checked + .checkbox, .ng-not-empty {
			background-color: $color_mantis;
			border-color: $color_mantis;

			&:after {
				content: url('../../svgs/checkmark.svg');
				position: absolute;
				left: .75rem;
				height: 9px;
				@include transform(translate(-50%, -50%));

			}
		}
	}
}

form.file-upload {
	padding-top: 1rem;
	input[type=submit] {
		margin-top: 1rem;
	}	
}