#broker_login {
	padding: 10vw 7.5vw;
	// @include scale(padding-right, 7.5vw, 20em, 15vw, 40em);
	// padding-bottom: 10vw;
	// @include scale(padding-left, 7.5vw, 20em, 15vw, 40em);

	h2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0;

		a {
			padding: .75rem;

			svg {
				display: block;
				width: 1.5rem;
				height: 1.5rem;
			}

			&:hover,
			&:focus {
				svg path {
					fill: $color_comet;
				}
			}
		}
	}

	.forms {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		max-width: 30rem;
		margin: 0 auto;

		@media (min-width: 60em) {
			flex-direction: row;
			justify-content: center;
			max-width: none;
		}

		header {
			font-size: 1.75rem;
		}

		.login_form {
			width: 100%;
			@include scale(padding, 1.5rem, 20em, 2.5rem, 40em);
			background-color: $color_white;
			border: 1px solid $color_mercury;

			@media (min-width: 60em) {
				flex: 1 1 50%;
				max-width: 30rem;
			}

			form {
				padding: 0;

				label.text {
					margin-top: 2rem;
				}

				input,
				select {
					border-top: 0;
					border-right: 0;
					border-left: 0;
					border-radius: 0;
				}

				input[type=submit],button {
					float: none;
					display: block;
					width: 100%;
					margin-top: 2rem;
					min-height: 3rem;
					color: white;
					font-family: $font_family_open_sans;
					background-color: $color_carrot_orange;
					border-color: $color_carrot_orange;
					border-radius: 8px;

					&:hover,
					&:focus {
						background-color: $color_comet;
						border-color: $color_comet;
					}
				}

				> a,
				.forgot-link {
					display: block;
					margin-top: 1.5rem;
					font-weight: 400;
					text-align: center;
				}

				.login-reset .tooltip {
					top: calc(100% + 1rem);
					bottom: auto;
					left: 50%;
					width: calc(100% + 5rem);
					margin-bottom: 2rem;
					border-top: .6875rem solid $color_comet;
					border-bottom: 0;
					transform: translateX(-50%);

					&:after {
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-top: 10px solid rgba($color_comet, 1) !important;
						content: '';
						height: 0;
						margin-left: -10px;
						position: absolute;
							top: -1.25rem !important;
							bottom: auto !important;
							left: 50%;
						width: 0;
						transform: rotate(180deg);
					}
				}

				.network_error div span {
					margin-top: -1.5rem;
				}
			}
		}

		.register_link {
			margin-top: 2.5rem;
			@include scale(padding, 1.5rem, 20em, 2.5rem, 40em);
			color: $color_white;
			text-align: center;
			background-color: rgba($color_comet, .9);
			background-image: url('../../svgs/signup-background.svg');
			background-position: 100% 100%;
			background-size: 18rem;
			background-repeat: no-repeat;
			background-attachment: scroll;

			@media (min-width: 60em) {
				flex: 1 1 50%;
				max-width: 30rem;
				height: 85%;
				margin-top: 0;
				text-align: left;
			}

			.button {
				margin-top: 2rem;
				background-color: $color_carrot_orange;
				border-color: $color_carrot_orange;
				border-radius: 8px;
				outline: none;

				&:hover,
				&:focus {
					color: $color_white;
					background-color: $color_comet;
					border-color: $color_comet;
				}
			}

			a,span {
				display: inline-block;
				margin-bottom: .5rem;
				color: $color_white;
				font-weight: 700;
				border-bottom: 1px solid $color_white;

				&:hover {
					color: $color_carrot_orange;
					border-color: $color_carrot_orange;
				}

				.glossarizer_replaced {
					text-decoration: none;
					border: 0;
				}
			}
		}
	}

	.broker_search {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		@media (min-width: 75em) {
			flex-wrap: nowrap;
		}

		label {
			flex: 0 1 auto;
			width: auto;
			margin-bottom: 0;

			span {
				color: $color_comet;
			}

			select,
			input {
				padding-top: .5rem;
				padding-bottom: .5rem;
			}

			&.select:after {
				top: calc(50% + .75rem);
			}
		}
	}

	.broker_search_results {
		flex: 1 1 auto;
		background-color: $color_white;
		border: 1px solid $color_alto;
		border-radius: 8px;

		> div:nth-of-type(1) {
			display: flex;
			flex-direction: column;

			@media (min-width: 30em) {
				flex-direction: row;
			}

			header {
				flex: 1 1 auto;
				padding: .75rem 1rem;
				color: $color_comet;
				text-align: center;
				border-bottom: 1px solid $color_alto;

				@media (min-width: 30em) {
					text-align: left;
				}
			}

			a {
				padding: .75rem 1rem;
				text-align: center;
				border-bottom: 1px solid $color_alto;

				@media (min-width: 30em) {
					border-left: 1px solid $color_alto;
				}

				svg {
					position: relative;
					top: -.125rem;
					transform: rotate(-90deg);
				}
			}
		}

		table {
			table-layout: auto;
			font-size: .75rem;
			border: 0;

			@media (min-width: 40em) {
				display: table;
			}

			thead {
				display: none;

				@media (min-width: 70em) {
					display: table-header-group;
				}

				tr {
					th {
						padding: 1rem;
						background-color: transparent !important;
						border-bottom: 1px solid $color_alto;
					}
				}
			}

			tbody {
				tr {
					display: block;
					padding: .75rem 0;

					&:not(:last-of-type) {
						border-bottom: 1px solid $color_alto;
					}

					@media (min-width: 70em) {
						display: table-row;
						border: 0;
					}

					td {
						display: block;
						padding: .25rem 1rem;
						background-color: transparent !important;

						&:before {
							content: attr(data-label) ":\00a0" ;
							color: $color_body;
							font-weight: 600;
						}

						@media (min-width: 70em) {
							display: table-cell;
							padding: 1rem;
							border-bottom: 1px solid $color_alto;

							&:before {
								display: none;
							}
						}

						&:last-of-type {
							width: 100%;
							text-align: right;

							@media (min-width: 70em) {
								width: 1px;
							}

							&:before {
								content: '';
							}

							.button {
								display: inline-block;
								min-width: 0;
								padding: .25rem 1rem;
								border-radius: 1000rem;
							}
						}
					}

					&.paid {
						td:nth-of-type(3),
						td:nth-of-type(4) {
							color: $color_sushi;
						}
					}

					&.denied {
						td:nth-of-type(3),
						td:nth-of-type(4) {
							color: $color_bittersweet;
						}
					}

					&.void {
						td:nth-of-type(3),
						td:nth-of-type(4) {
							color: $color_bittersweet;
							text-decoration: line-through;
						}
					}

					&.reversed {
						td:nth-of-type(3),
						td:nth-of-type(4) {
							color: $color_sushi;
						}
					}

					&:last-of-type td {
						border-bottom: 0;
					}
				}
			}
		}
	}
}

body[data-contrast-mode=on] {
	.brokers {
		background-color: $color_black;
		background-image: none;

		.login_form {
			background-color: $color_black;
			border: 1px solid $color_white;
		}

		.register_link {
			background-color: $color_black;
			border: 1px solid $color_white;
			border-top: 0;

			@media (min-width: 60em) {
				border-top: 1px solid $color_white;
				border-left: 0;
			}
		}
	}
}
