.member_responsibility_totals {
	@include flex(1 1 auto);
	margin-bottom: 2.25rem;
	background-color: $color_white;
	border: 1px solid $color_alto;
	border-radius: 8px;

	@media (min-width: 70em) {
		margin-bottom: 0;
		margin-right: 2.25rem;
	}

	> div:nth-of-type(1) {
		@include flexbox();
		@include flex-direction(column);

		@media (min-width: 30em) {
			@include flex-direction(row);
		}

		header {
			@include flex(1 1 auto);
			padding: .75rem 1rem;
			color: $color_comet;
			text-align: center;
			border-bottom: 1px solid $color_alto;

			@media (min-width: 30em) {
				text-align: left;
			}

			> small {
				display: block;
				margin-top: 4px;
				color: $color_carrot_orange;

				@media (min-width: 50em) {
					float: right;
				}
			}
		}

		div {
			padding: 0; //.75rem 1rem;
			text-align: center;
			border-bottom: 1px solid $color_alto;

			@media (min-width: 30em) {
				border-left: 1px solid $color_alto;
			}

			.select {
				margin: 0;

				select {
					background-color: transparent;
					border: 0;
					padding: .875rem;
					color: $color_dove_gray;
				}
			}
		}
	}

	> div:nth-of-type(2) {
		$graph_break_to_desktop: 80em;

		@include flexbox();
		@include flex-direction(column);

		@media (min-width: $graph_break_to_desktop) {
			@include flex-direction(row);
		}

		.deductible,
		.out_of_pocket_max {
			@include flex(1 1 auto);
			padding: 1.5rem;

			&:nth-of-type(2) {
				border-top: 1px solid $color_alto;
			}

			@media (min-width: $graph_break_to_desktop) {
				&:nth-of-type(2) {
					min-width: 50%;
					width: 50%;
					max-width: 50%;
					border-top: 0;
					border-left: 1px solid $color_alto;
				}
			}

			> header {
				font-size: 1.25rem;
				letter-spacing: .016em;
				text-align: center;
			}

			> div {
				@include flexbox();
				@include flex-direction(column);
				padding: 1.5rem 0;

				@media (min-width: $graph_break_to_desktop) {
					@include flex-direction(row);
					@include align-items(center);
				}

				> div:nth-of-type(1) {
					text-align: center;

					@media (min-width: $graph_break_to_desktop) {
						@include flex(1 1 50%);
						width: 50%;
						max-width: 50%;
						margin-right: 1.5rem;
					}

					svg {
						width: 9.75rem;
						height: 9.75rem;
						margin: 0 auto;

						text {
							font-weight: 600;
						}
					}
				}

				> div:nth-of-type(2) {
					text-align: center;

					@media (min-width: $graph_break_to_desktop) {
						@include flex(1 1 50%);
						width: 50%;
						max-width: 50%;
						text-align: left;
					}

					div {
						&:first-of-type {
							margin-bottom: 1rem;
						}

						header {
							color: $color_emporer;
							font-size: .875rem;
						}

						span {
							display: block;
							color: $color_gray;
							font-size: 2rem;
							line-height: 1
						}
					}
				}
			}
		}
	}

	+ .member_responsibility_totals {
		margin-top: 3rem;
	}

	.empty {
		width: 100%;

		p {
			width: 100%;
			padding: 2rem;
			text-align: center;
		}
	}
}
