.resource_listing {
	padding: 3rem 1.5rem;
	background-color: $color_alabaster;

	h2 {
		max-width: 73rem;
		margin: 3rem auto;
		padding: 0 1.5rem;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.col {
		header {
			display: flex;

			svg {
				flex-shrink: 0;
			}

			div {
				padding-left: 1.25rem;

				a {
					display: block;
					margin-top: -.25rem;
					color: $color_comet;
					font-weight: 400;
					font-size: 1.25rem;
					line-height: 1.25;

					&:hover {
						color: $color_carrot_orange;
					}
				}

				.cat_name {
					display: block;
					font-weight: 600;
					font-size: .875rem;
					line-height: 1.375;
				}
			}
		}

		p {

		}
	}
}

body[data-contrast-mode=on] {
	.resource_listing {
		background-color: $color_black;
		border-top: 1px solid $color_white;

		.col header {
			svg {
				.pdf {
					path:nth-of-type(1) {
						fill: $color_white;
					}

					path:nth-of-type(2) {
						fill: darken($color_white, 30);
					}

					path:nth-of-type(3) {
						fill: $color_black;
					}
				}

				.http path {
					fill: $color_white;
				}
			}

			div a {
				color: $color_white;
			}
		}
	}
}