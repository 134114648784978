article#group-billing-controller {
  label.select {
    select[name=reportname] {
      width: 20rem !important;
    }
  }

  .field-wrapper-checkbox {
    flex: 0 0 auto;
    order: 1;
    line-height: 0;

    .checkbox {
      position: relative;
      display: inline-block;
      width: 1.5625rem;
      height: 1.5625rem;
      margin-right: .875rem;
      border: $input_border_thickness solid #858585;
      border-radius: $input_border_radius;
      cursor: pointer;
      transition: all .25s;

      &::after {
        content: url('../../svgs/checkbox-radio-selected.svg');
        position: absolute;
        top: 50%;
        left: 50%;
        width: .6875rem;
        height: .5625rem;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: all .25s;
      }
    }

    input[type=checkbox] {
      position: absolute;
      opacity: 0;
      z-index: -1;

      &:checked + .checkbox {
        background-color: $color_checkbox_background;
        border-color: $color_checkbox_background;

        &::after {
          opacity: 1;
        }
      }

      &:disabled + .checkbox {
        background-color: #eee;
        border-color: #eee;

        &::after {
          content: url('../../svgs/checkbox-radio-disabled.svg');
          opacity: 1;
        }
      }
    }

    &.error:after {
      display: none;
    }

    &:hover,
    &.hover {
      input[type=checkbox]:not(:checked):not(:disabled) + .checkbox {
        background-color: #eee;
        border-color: #eee;

        &::after {
          content: url('../../svgs/checkbox-radio-hover.svg');
          opacity: 1;
        }
      }
    }
  }

  .buttons {
    .export-all-align {
      display: flex;
      white-space: nowrap;

      span {
        margin-right: 1rem;
      }
    }
  }

  .group-billing-statements {
    padding-bottom: 3rem;
  }
}

.billing-statement {
  color: $color_carrot_orange;
  font-weight: 800;
}
