.related-resources {
	max-width: 73rem;
	margin: 3rem auto;
	padding: 0 1.5rem;

	ul {
		@include columns(2.1875rem, 2.1875rem, (
			45em : 2,
			75em : 3
		));
		list-style: none;
		margin: 0;
		padding: 0;

		header {
			display: flex;

			svg {
				flex-shrink: 0;

				path:first-of-type {
					fill: $color_comet;
				}

				#http-b {
					display: none;
				}
			}

			.file-type {
				position: relative;
				svg.pdf {
					path:nth-child(3) {
						fill: $color_comet;
						display: none;
					}
				}

				.extension {
					position: absolute;
					color: white;
					font-weight: 700;
					font-size: 0.65rem;
					top: 14.45px;
					left: 28.7px;
					text-transform: uppercase;
					width: 50%;
					height: 50%;
				}
			}

			div {
				padding-left: 1.25rem;

				a {
					display: block;
					margin-top: -.25rem;
					color: $color_comet;
					font-weight: 400;
					font-size: 1.25rem;
					line-height: 1.25;
					margin-bottom: 1.75rem;

					&:hover {
						color: $color_carrot_orange;
					}
				}

				.cat_name {
					display: block;
					font-weight: 600;
					font-size: .875rem;
					line-height: 1.375;
				}
			}
		}
	}
}