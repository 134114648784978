.swal-modal.test-login-search {

  width: 100%;
  max-width: 800px;

  @media (min-width: 84em) {
    // max-width: 300px;
  }

  section {
    h2 {
      a {
        svg {
          display: inline;
          width: 1.5rem;
          height: 1.5rem;

          .Style-Guide-Desktop {
            stroke: black;
          }
        }
      }
    }
  }

  .login_search {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media (min-width: 84em) {
      // flex-wrap: nowrap;
    }

    label {
      flex: 0 1 auto;
      width: auto;
      margin-right: 1rem;
      margin-bottom: 0;

      span {
        color: $color_comet;

        &.clear {
          position: absolute;
          top: 2.5rem;
          right: .5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.5rem;
          height: 1.5rem;
          background-color: #ed822b;
          border-radius: 50%;
          cursor: pointer;

          svg {
            display: block;
            width: 1rem;
            height: 1rem;

            path {
              fill: white;
            }
          }
        }
      }

      select,
      input {
        padding-top: .5rem;
        padding-bottom: .5rem;
        border-radius: 4px;
      }

      input {
        padding-right: 2.25rem;
      }

      &.select:after {
        top: calc(50% + .75rem);
      }
    }

    input[type=submit] {
      min-width: 8rem;
      margin-top: 2.75rem;
      padding: .4375rem 1.5rem;
      border-radius: 1000rem;
    }
  }

  .test_login_search_results {
    flex: 1 1 auto;
    display: block;
    margin-top: 2rem;
    background-color: $color_white;
    border: 1px solid $color_alto;
    border-radius: 8px;

    > div:nth-of-type(1) {
      display: flex;
      flex-direction: column;

      @media (min-width: 30em) {
        flex-direction: row;
      }

      header {
        flex: 1 1 auto;
        padding: .75rem 1rem;
        color: $color_comet;
        text-align: center;
        border-bottom: 1px solid $color_alto;

        @media (min-width: 30em) {
          text-align: left;
        }
      }

      a {
        padding: .75rem 1rem;
        text-align: center;
        border-bottom: 1px solid $color_alto;

        @media (min-width: 30em) {
          border-left: 1px solid $color_alto;
        }

        svg {
          position: relative;
          top: -.125rem;
          transform: rotate(-90deg);
        }
      }
    }

    table {
      table-layout: auto;
      margin: 0;
      font-size: .75rem;
      border: 0;

      @media (min-width: 40em) {
        display: table;
      }

      thead {
        display: none;

        @media (min-width: 70em) {
          display: table-header-group;
        }

        tr {
          th {
            padding: 1rem;
            background-color: transparent !important;
            border-bottom: 1px solid $color_alto;
          }
        }
      }

      tbody {
        tr {
          display: block;
          padding: .75rem 0;

          &:not(:last-of-type) {
            border-bottom: 1px solid $color_alto;
          }

          @media (min-width: 70em) {
            display: table-row;
            border: 0;
          }

          td {
            display: block;
            padding: .25rem 1rem;
            background-color: transparent !important;
            text-align: left;

            &:before:not(.empty) {
              content: attr(data-label) ":\00a0" ;
              color: $color_body;
              font-weight: 600;
            }

            @media (min-width: 70em) {
              display: table-cell;
              padding: 1rem;
              border-bottom: 1px solid $color_alto;

              &:before {
                display: none;
              }
            }

            &:last-of-type {
              width: 100%;
              text-align: right;

              @media (min-width: 70em) {
                width: 1px;
              }

              &:before {
                content: '';
              }

              .button {
                display: inline-block;
                min-width: 0;
                margin: 0;
                padding: .25rem 1rem;
                border-radius: 1000rem;
              }
            }
          }

          &.paid {
            td:nth-of-type(3),
            td:nth-of-type(4) {
              color: $color_sushi;
            }
          }

          &.denied {
            td:nth-of-type(3),
            td:nth-of-type(4) {
              color: $color_bittersweet;
            }
          }

          &.void {
            td:nth-of-type(3),
            td:nth-of-type(4) {
              color: $color_bittersweet;
              text-decoration: line-through;
            }
          }

          &.reversed {
            td:nth-of-type(3),
            td:nth-of-type(4) {
              color: $color_sushi;
            }
          }

          &:last-of-type td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
