.plan-card {
  width: 400px;
  min-width: 400px;
  box-shadow: 1px 1px 3px;
  margin: 2%;
  background-size: 100%;
  background-image: url('../../svgs/registration-waves.svg');
  background-repeat: no-repeat;
  background-position: bottom;

  @media screen and (max-width: 420px) {
    width: 100%;
    min-width: 100%;
  }

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease-in-out;
  }

  .plan-card-content {
    width: 100%;
    padding-right: 5%;
    position: relative;
    text-align: right;

    .plan-card-name {
      color: $color_black;
      letter-spacing: 2px;
      margin-bottom: -15px;
    }

    .plan-card-dates {
      font-size: .75rem;
      margin-bottom: 1%;
    }

    .plan-content {
      text-align: left;
      padding-bottom: 10%;

      .plan-card-info {
        font-size: .65rem;
        margin-bottom: -13px;
      }

      .content-padding {
        padding: 2%;
        @media screen and (max-width: 400px) {
          width: 100%;
          text-align: center;
        }
      }
    }

    .plan-card-documents {
      font-size: .75rem;
    }

    .plan-dropdown {
      position: absolute;
      display: none;
      width: 100%;
      padding: 3%;
      background-color: $color_white;
      box-shadow: 1px 1px 3px;
      z-index: 100;

      p {
        font-size: .7em;
      }

    }

    @media screen and (max-width: 350px) {
      width: 100%;
      text-align: center;
      padding-right: 0;
      margin: 1% 0;
    }
  }
}

.plans-component-width {
  div {
    &:first-of-type {
      @media (min-width: 34em) {
        max-width: 100% !important;
        margin-right: 0 !important;
      }
    }
  }
}

.plan-card-container {
  @include flexbox();
  flex-flow: column;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.plan-card-container.plan-content {
  margin-left: 2%;
}

.plans-component {
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  max-width: 100% !important;

  div {
    &:first-of-type {
      @media (min-width: 34em) {
        margin-right: 0 !important;
      }
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .75rem 1.25rem;
    color: $color_primary_purple;
    border-bottom: 1px solid #dbdbdb;

    .years {
      a {
        padding: .75rem;
        color: #d4d4d4;

        &:hover,
        &:focus {
          color: $color_secondary_orange;
        }
      }

      a.active {
        color: $color_secondary_orange;
      }
    }

  }
}
