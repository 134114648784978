#update-browser {
	background-color: rgba($color_error, .1);
	border-bottom: 1px solid rgba($color_error, .4);
	color: $color_error;
	margin: 0;
	padding: 0;

	p {
		margin: 0 auto;
		padding: 1em;
		width: 90%;
	}

	a {
		color: $color_error;
	}
}