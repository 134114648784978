.tooltip.password-rules {
	margin: 0;
	padding: 1rem;

	p, span {
		padding: 0;
		color: $color_form_errors;
      margin-top: 0 !important;
      text-align: left !important;

		&.success {
			color: $color_mantis;
		}
	}
}

