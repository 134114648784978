.table-overflow {
	width: 100%;
	margin-bottom: 2rem;
	overflow-x: auto;

	table {
		min-width: 40rem;
	}
}

table {
	width: 100%;
	border: 1px solid $color_table_borders;
	border-radius: 4px;

	thead {
		tr {
			th {
				padding: 1.25rem;
				font-weight: 600;
				font-size: .875rem;
				font-family: $font_family_table_headers;
				text-align: left;
				border-bottom: 1px solid $color_table_borders;

				&.text-left {
					text-align: left;
				}

				&.text-center {
					text-align: center;
				}

				&.text-right {
					text-align: right;
				}

				&.title-link {
					padding: 0;

					> div {
						display: flex;
						justify-content: space-between;
						font-weight: 400;

						span {
							flex: 1 1 auto;
							padding: .75rem 1.25rem;
							color: $color_secondary_purple;
						}

						a {
							flex: 0 1 auto;
							padding: .75rem 1.25rem;
							color: $color_body;
							font-weight: 400;
							border-left: 1px solid $color_table_borders;

							svg {
								position: relative;
								top: -1px;
								display: inline-block;
								width: .625rem;
								height: .5rem;

								path {
									fill: currentColor;
									transition: all .25s;
								}
							}

							&:hover,
							&:focus {
								color: $color_secondary_orange;
							}
						}
					}
				}

				&.sorted {
					> div {
						display: flex;
						align-items: center;

						.sort {
							margin-left: 0.7rem;

							a {
								display: block;

								svg {
									display: block;
									width: 10px;
									height: 10px;
								}

								&:first-of-type {
									margin-bottom: .25rem;

									svg {
										transform: rotate(-90deg);
									}
								}

								&:last-of-type svg {
									transform: rotate(90deg);
								}

								&.active,
								&:hover,
								&:focus {
									svg path {
										fill: $color_secondary_orange;
										stroke: $color_secondary_orange;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	tbody {
		tr {
			td {
				padding: 1.25rem;

				&.text-left {
					text-align: left;
				}

				&.text-center {
					text-align: center;
				}

				&.text-right {
					text-align: right;
				}

				&.no-results-message, &.empty {
					padding: 4rem 0;
					text-align: center;
				}

				span {
					&.paid {
						color: #74b943;
					}

					&.denied {
						color: #fd6a6a;
					}
				}

				a {
					display: inline-flex;
					align-items: center;
					padding: .5rem;

					> svg,
					> .svg {
						display: block;
						width: .875rem;
						height: .875rem;

						&:first-child {
							margin-right: .75rem;
						}

						&:last-child {
							margin-left: .75rem;
						}

						path {
							fill: currentColor;
						}

						svg {
							display: block;
							width: .875rem;
							height: .875rem;
						}
					}
				}

				&.link-col {
					padding: 0;

					a {
						padding: 1.25rem;
					}
				}

				.has-dropdown {
					position: relative;

					a {}

					> div {
						position: absolute;
						top: 100%;
						right: 0;
						display: none;
						max-width: 18rem;
						background-color: white;
						border: 1px solid $color_table_borders;
						border-radius: 5px;
						box-shadow: 0 3px 5px rgba(black, .5);
						z-index: 3;

						ul {
							list-style: none;
							margin: 0;
							padding: 0;

							li {
								&:not(:last-of-type) {
									border-bottom: 1px solid $color_table_borders;
								}

								a {
									display: flex;
									align-items: center;
									padding: .5rem 1rem;
									font-size: .875rem;
									white-space: nowrap;

									.svg,
									svg {
										flex: 0 0 1.5rem;
										margin-right: .75rem;
										margin-left: 0;

										svg path {
											fill: currentColor;
										}
									}
								}
							}
						}
					}
				}
			}

			&.hover:not(.no-hover),
			&:hover:not(.no-hover) {
				background-color: #eee;
			}

			&:not(:last-of-type) td {
				border-bottom: 1px solid $color_table_borders;
			}

			&.not-last td {
				border-bottom: 1px solid $color_table_borders !important;
			}
		}
	}
}

.table-overflow {
	width: 100%;
	margin-bottom: 2rem;
	overflow-x: auto;

	table {
		min-width: 40rem;
	}
}

table {
	table-layout: fixed;
	border-collapse: collapse;
	width: 100%;
	border: 1px solid $color_table_borders;

	thead {
		tr {
			border-bottom: 1px solid $color_table_borders;

			th {
				padding: .75rem;
				font-weight: 400;
				font-size: .875rem;
				font-family: $font_family_table_headers;
				text-align: left;
				background-color: $color_table_header_background;
			}
		}
	}

	tbody {
		tr {
			td {
				padding: 1rem .75rem;

				&.no-results-message {
					padding: 4rem 0;
					text-align: center;
				}
			}

			&:nth-of-type(even) td,
			&.even td {
				background-color: $color_table_alternate_rows;
			}
		}
	}
}
