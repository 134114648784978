.ins_claims_eob {
  $inc_claims_eob_breakpoint_to_desktop : 60em;

  @include flexbox();
  @include flex-direction(column);
  padding: 1.5rem 0;

  @media (min-width: $inc_claims_eob_breakpoint_to_desktop) {
    @include flex-direction(row);
    @include align-items(flex-start);
    max-width: 80rem;
    margin-right: auto;
    margin-left: auto;
  }

  .ins_id {
    @include flex(0 1 auto);
    @include flexbox();
    @include flex-direction(column);
    margin-bottom: 2.25rem;
    background-color: $color_white;
    border: 1px solid $color_alto;
    border-radius: 8px;

    @media (min-width: $inc_claims_eob_breakpoint_to_desktop) {
      max-width: 26rem;
      margin-bottom: 0;
      margin-right: 2.25rem;
    }

    > header {
      padding: .75rem 1rem;
      color: $color_comet;
      text-align: center;
      border-bottom: 1px solid $color_alto;

      @media (min-width: $inc_claims_eob_breakpoint_to_desktop) {
        text-align: left;
      }
    }

    > div {
      @include flexbox();
      @include flex-direction(column);
      @include align-items(center);
      padding: 1.5rem 1rem;
      text-align: center;

      @media (min-width: 80em) {
        @include flex-direction(row);
        @include flex-wrap(wrap);
        text-align: left;
      }

      > div {
        @include flex(1 1 auto);
        margin-bottom: 1.25rem;

        @media (min-width: 80em) {
          min-width: calc((100% - 1.5rem) / 2.0001);
          width: calc((100% - 1.5rem) / 2.0001);
          max-width: calc((100% - 1.5rem) / 2.0001);
        }

        header {
          color: $color_comet;
          font-weight: 700;
          font-size: .875rem;
        }

        span {
          display: block;
        }
      }
    }

    > a {
      padding: 0 1.25rem;
      color: $color_white;
      font-weight: 500;
      line-height: 3rem;
      text-align: center;
      background-color: $color_carrot_orange;
      border-radius: 0 0 8px 8px;
    }
  }

  .claims_eob {
    @include flex(1 0 auto);
    background-color: $color_white;
    border: 1px solid $color_alto;
    border-radius: 8px;

    > div:nth-of-type(1) {
      @include flexbox();
      @include flex-direction(column);

      @media (min-width: 30em) {
        @include flex-direction(row);
      }

      header {
        @include flex(1 1 auto);
        padding: .75rem 1rem;
        color: $color_comet;
        text-align: center;
        border-bottom: 1px solid $color_alto;

        @media (min-width: 30em) {
          text-align: left;
        }
      }

      a {
        padding: .75rem 1rem;
        color: inherit;
        text-align: center;
        border-bottom: 1px solid $color_alto;
        font-weight: 400;

        @media (min-width: 30em) {
          border-left: 1px solid $color_alto;
        }

        svg {
          width: .6875rem;
          height: .6875rem;
          @include transform(rotate(-90deg));

          path {
            @include transition(.25s all);
          }
        }

        &:hover,
        &:focus {
          color: $color_carrot_orange;

          svg path {
            fill: $color_carrot_orange;
          }
        }
      }
    }

    table {
      table-layout: auto;
      font-size: .75rem;
      border: 0;

      @media (min-width: 40em) {
        display: table;
      }

      thead {
        display: none;

        @media (min-width: 70em) {
          display: table-header-group;
        }

        tr {
          th {
            padding: 1rem;
            background-color: transparent !important;
            border-bottom: 1px solid $color_alto;

            &.icon {
              width: 1px;
            }
          }
        }
      }

      tbody {
        tr {
          display: block;
          padding: .75rem 0;

          &:hover {
            background-color: $color_white;
          }

          &:not(:last-of-type) {
            border-bottom: 1px solid $color_alto;
          }

          @media (min-width: 70em) {
            display: table-row;
            border: 0;
          }

          td {
            display: block;
            padding: .25rem 1rem;
            background-color: transparent !important;
            cursor: pointer;

            &.empty {
              font-weight: 600;
              font-size: 1rem;
            }

            &.icon {
              width: 1px;
            }

            &:not(.icon):not(.empty):before {
              content: attr(data-label) ":\00a0" ;
              color: $color_body;
              font-weight: 600;
            }

            @media (min-width: 70em) {
              display: table-cell;
              padding: 1rem;
              border-bottom: 1px solid $color_alto;

              &:before {
                display: none;
              }
            }

            svg {
              width: 1rem;
              height: 1rem;
            }
          }

          &:hover td {
            background-color: $color_alabaster2 !important;
          }

          &.paid {
            td:nth-of-type(3),
            td:nth-of-type(4) {
              color: $color_sushi;
            }
          }

          &.denied {
            td:nth-of-type(3),
            td:nth-of-type(4) {
              color: $color_bittersweet;
            }
          }

          &.void {
            td:nth-of-type(3),
            td:nth-of-type(4) {
              color: $color_bittersweet;
              text-decoration: line-through;
            }
          }

          &.reversed {
            td:nth-of-type(3),
            td:nth-of-type(4) {
              color: $color_sushi;
            }
          }

          &:last-of-type td {
            border-bottom: 0;
          }

          &:not(:last-of-type) td {
            border-bottom: none;
          }
        }
      }
    }
  }
}