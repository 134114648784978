.card {
	&.image {
		width: 100%;
		max-width: 21.875rem;
		text-align: center;
		border: 1px solid #dbdbdb;
		border-radius: 8px;

		figure {
			height: 14.375rem;
			border-radius: 8px 8px 0 0;
		}

		.content {
			padding-top: 1.5rem;
			@include scale(padding-right, 1rem, 20rem, 2.5rem, 60rem);
			padding-bottom: 1.5rem;
			@include scale(padding-left, 1rem, 20rem, 2.5rem, 60rem);

			header {
				color: $color_secondary_purple;
				font-size: 1.125rem;
			}

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&.text {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 2.5rem;
		text-align: center;
		border: 1px solid #dbdbdb;
		border-radius: 8px;

		header {
			font-size: 1.125rem;
		}

		div {
			color: $color_secondary_purple;
			font-size: 3rem;
		}
	}

	&.icon {
		padding: 2.5rem;
		text-align: center;
		border: 1px solid #dbdbdb;
		border-radius: 8px;

		.icon {
			svg,
			img {
				display: inline-block;
				width: 5.75rem;
				height: 5.75rem;
			}

			svg path {
				fill: $color_secondary_purple;
			}
		}

		header {
			font-weight: 600;
		}
	}

	&.news {
		display: inline-flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 25rem;
		padding: 2.5rem 1.25rem;
		box-shadow: 0 7px 11px rgba(#dbdbdb, .9);

		header {
			color: $color_primary_purple;
			font-size: 1.5rem;
			letter-spacing: .031828703703704em;
			line-height: 1.2;
		}

		time {
			letter-spacing: .03125em;
		}
	}
}