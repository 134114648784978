body {
	display: block;
}

#registration-content,
#login-content {
	$breakpoint_to_full_logo			: 40em;
	$breakpoint_to_flex_row_home		: 50em;
	$breakpoint_to_flex_row_interior	: 60em;
	$breakpoint_to_row_of_four			: 80em;
	.site_nav {
		position: relative;
		box-shadow: 0 0 1.25rem rgba($color_black, .2);

		&.home {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: 5000;
		}

		&.is_menu_shown {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 5000;
		}

		&.plan_finder {
			border-bottom: 1px solid $color_alto;
			box-shadow: none;
		}

		&.provider_search {
			border-bottom: 1px solid $color_alto;
			box-shadow: none;
		}

		.common {
			display: flex;
			justify-content: flex-end;
			background: $color_white;

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 4.5rem;

				&.logo {
					flex: 1 1 100%;
					justify-content: flex-start;
					width: 100%;
					margin-right: auto;
					padding: .75rem;

					// IE11
					@media (-ms-high-contrast: none) {
						margin-right: 0;
					}

					svg {
						display: block;
						width: 100%;
						max-width: 3.375rem;
						max-height: 100%;

						&:nth-of-type(2) {
							display: none;
						}

						@media (min-width: $breakpoint_to_full_logo) {
							&:nth-of-type(1) {
								display: none;
							}

							&:nth-of-type(2) {
								display: block;
								max-width: 10.125rem;
							}
						}
					}
				}

				&.login {
					flex: 1 0 auto;
					padding: 0;
					@include scale(padding-right, .75rem, 20em, 1.5rem, $breakpoint_to_full_logo);
					@include scale(padding-left, .75rem, 20em, 1.5rem, $breakpoint_to_full_logo);
					color: $color_jacarta;
					font-weight: 700;
					text-transform: uppercase;
					border-left: 1px solid $color_mercury;

					span {
						display: none;
					}

					svg {
						display: block;
						width: 1.5rem;
						height: 1.5rem;

						path {
							fill: $color_jacarta;
						}
					}

					&:hover {
						color: $color_carrot_orange;

						svg path {
							fill: $color_carrot_orange;
						}
					}

					@media (min-width: 30em) {
						span {
							display: block;
						}

						svg {
							display: none;
						}
					}
				}

				&.search,
				&.toggle {
					flex: 0 0 auto;
					min-width: 4.25rem;
					padding: 0;
					@include scale(padding-right, .75rem, 20em, 1.5rem, $breakpoint_to_full_logo);
					@include scale(padding-left, .75rem, 20em, 1.5rem, $breakpoint_to_full_logo);
					border-left: 1px solid $color_mercury;
					cursor: pointer;

					&.menu {
						svg:nth-of-type(2) {
							display: none;
						}

						&.on {
							svg:nth-of-type(1) {
								display: none;
							}

							svg:nth-of-type(2) {
								display: block;
							}
						}
					}

					svg {
						path {
							transition: .25s all;
						}
					}

					&:hover {
						svg {
							path {
								fill: $color_carrot_orange;
							}
						}
					}
				}
			}
		}

		& > .menu {
			position: absolute;
			top: 4.5rem;
			right: 0;
			bottom: 0;
			left: 0;
			display: none;
			//height: calc(100% - 4.5rem);
			background-color: adjust_color($color_comet, $hue: -.67653, $saturation: -4.93151, $lightness: 14.90196);
			background-image: linear-gradient(to bottom, adjust_color($color_comet, $hue: -.67653, $saturation: -4.93151, $lightness: 14.90196) 0%, adjust_color($color_comet, $hue: -1.58562, $saturation: -4.93151, $lightness: 29.21569) 100%);  //color: color-diff($color_comet, #AEABC1);
			z-index: 1;

			.container {
				@extend %clearfix;
				position: relative;
				height: 100%;
				//background: transparent url(/assets/images/menu-background.svg) 100% 100% / contain no-repeat scroll;
				overflow-x: hidden;
				overflow-y: auto;
				z-index: 3;

				@media (min-width: 36.5625em) {
					background-size: 36.5625rem auto;
				}

				.custom {
					position: relative;
					z-index: 5;

					@media (min-width: $breakpoint_to_flex_row_home) {
						display: flex;
					}

					@media (min-width: $breakpoint_to_row_of_four) {
						float: left;
						flex-direction: column;
						width: 25%;
						min-height: 40rem;
						height: 100%;
					}

					& > div {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						position: relative;
						width: 100%;
						min-height: 18rem;
						max-height: 100vw;
						padding: 1.5rem;
						color: $color_white;
						text-align: center;
						z-index: 1;

						@media (min-width: $breakpoint_to_flex_row_home) {
							height: 24rem;
						}

						@media (min-width: $breakpoint_to_row_of_four) {
							flex: 1 0 auto;
							height: 50%;
						}

						&.i_have {
							background-color: adjust_color($color_jacarta, $hue: -1.83007, $saturation: -26.15828, $lightness: 2.15686);  //color: color_diff($color_jacarta, #484657); color: color_diff($color_jacarta, #8b9297);
							background-image: linear-gradient(to bottom, adjust_color($color_jacarta, $hue: -1.83007, $saturation: -26.15828, $lightness: 2.15686) 0%, adjust_color($color_jacarta, $hue: -43.88889, $saturation: -31.53176, $lightness: 28.23529) 100%);
						}

						&.i_am {
							background-color: adjust_color($color_mantis, $hue: -.12687, $saturation: .64083, $lightness: 4.70588);  //color: color_diff($color_mantis, #89c65c); color: color_diff($color_mantis, #96cc6d);
							background-image:  linear-gradient(to bottom, adjust_color($color_mantis, $hue: -.12687, $saturation: .64083, $lightness: 4.70588) 0%, adjust_color($color_mantis, $hue: -.54991, $saturation: .68237, $lightness: 9.21569) 100%);
						}

						.img_background {
							@extend %fullcoverage;
							background-color: transparent;
							background-position: center;
							background-size: cover;
							background-repeat: no-repeat;
							background-attachment: scroll;
							opacity: .25;
							z-index: 2;
						}

						header {
							position: relative;
							line-height: 1.25;
							text-transform: uppercase;
							z-index: 3;

							span {
								display: block;

								&:nth-of-type(1) {
									font-weight: 300;
									font-size: 1.5rem;
								}

								&:nth-of-type(2) {
									font-weight: 700;
									font-size: 2rem;
								}
							}
						}

						.button {
							position: relative;
							margin: 2rem auto 1rem auto;
							color: $color_dove_gray;
							white-space: nowrap;
							background-color: $color_white;
							border-color: $color_white;
							z-index: 4;

							&:hover {
								color: $color_white;
								background-color: $color_carrot_orange;
								border-color: $color_carrot_orange;
							}
						}

						p {
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							padding: 0 1.5rem;
							line-height: 1.25;
							z-index: 4;
						}
					}
				}

				.standard {
					padding: 2rem 1.5rem;
					color: $color_white;
					text-align: center;
					text-transform: uppercase;
					border-top: 1px solid rgba($color_white, .3);

					@media (min-width: $breakpoint_to_flex_row_home) {
						display: flex;
						flex-direction: column;
						justify-content: center;
						border-top: 0;

						&:not(:last-of-type) {
							border-right: 1px solid rgba($color_white, .3);
						}
					}

					header {
						margin-bottom: 1.5rem;
						font-weight: 700;
						font-size: 1.75rem;
						letter-spacing: .08125em;
					}

					a {
						display: block;
						margin-bottom: .75rem;
						color: inherit;
						font-weight: 400;
						font-size: 1.125rem;
						letter-spacing: .0525em;

						&:hover {
							color: $color_jacarta;
						}
					}
				}

				&.home .standard {
					@media (min-width: $breakpoint_to_flex_row_home) {
						float: left;
						width: 50%;
						min-height: 22rem;
						height: calc(50vh - 2.25rem);

						&.connect {
							clear: both;
							float: none;
							width: 100%;
							border-top: 1px solid rgba($color_white, .3);
						}
					}

					@media (min-width: $breakpoint_to_flex_row_interior) {
						float: left;
						width: 33.33333%;
						min-height: 34rem;
						height: calc(100vh - 4.5rem);
						margin-bottom: -13.25rem;
						padding-bottom: 13.25rem;

						&.connect {
							clear: none;
							float: left;
							width: 33.33333%;
						}

						&.learn,
						&.connect {
							border-top: 0;
						}
					}
				}

				&.interior .standard {
					@media (min-width: $breakpoint_to_flex_row_home) {
						float: left;
						width: 50%;
						min-height: 22rem;
						height: 50vw;

						&.find,
						&.learn {
							border-bottom: 1px solid rgba($color_white, .3);
						}

						&.connect {
							clear: both;
							width: 100%;
						}
					}

					@media (min-width: $breakpoint_to_flex_row_interior) {
						float: left;
						width: 33.33333%;
						min-height: 34rem;
						height: calc(100vh - 24rem);
						margin-bottom: -13.25rem;
						padding-bottom: 13.25rem;

						&.connect {
							clear: none;
							float: left;
							width: 33.33333%;
						}

						&.learn,
						&.connect {
							border-top: 0;
						}
					}

					@media (min-width: $breakpoint_to_row_of_four) {
						clear: none;
						width: 25%;
						max-width: 25%;
						min-height: 40rem;
						height: 100%;

						&.find,
						&.learn,
						&.connect {
							clear: none;
							margin-bottom: 0;
							padding-bottom: 13.25rem;
							border-top: 0;

							@media (min-height: 44.5rem) {
								margin-bottom: 0;
								padding-bottom: 3rem;
							}
						}
					}
				}
			}

			.request_quote {
				position: relative;
				flex: 1 0 auto;
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: 100%;
				width: 100%;
				max-width: 100%;
				@include scale(height, 7.625rem, 20rem, 13.25rem, 40rem);
				text-align: center;
				z-index: 4;

				@media (min-width: $breakpoint_to_flex_row_home) {
					justify-content: flex-end;
					align-items: flex-end;
					//background: transparent url(/assets/images/menu-quote-background.svg) 100% 100% no-repeat scroll;
				}

				.button {
					position: relative;
					margin-bottom: 2.25rem;
					z-index: 3;

					@media (min-width: $breakpoint_to_flex_row_home) {
						margin-right: 3.25rem;
						margin-bottom: 3.25rem;
						margin-left: 0;
					}
				}
			}

			.container.home {
				.request_quote {
					@media
					(min-height: 80em),
					(min-width: $breakpoint_to_flex_row_home) and (min-height: 64.5em),
					(min-width: $breakpoint_to_flex_row_interior) and (min-height: 35em) {
						// position: absolute;
						// right: 0;
						// bottom: 0;
						// left: 0;
						// z-index: 4;
					}
				}
			}

			.container.interior {
				.request_quote {
					@media (min-width: $breakpoint_to_row_of_four) {
						top: -13.25rem;
						margin-bottom: -13.25rem;

						@media (min-height: 44.5rem) {
							position: absolute;
							top: auto;
							right: 0;
							bottom: 0;
							left: 0;
							margin-bottom: 0;
							z-index: 4;
						}
					}
				}
			}
		}
	}

	body[data-contrast-mode=on] {
		.site_nav {
			border-bottom: 1px solid $color_white;

			.common {
				background-color: $color_black;

				a.logo svg {
					path, polyline {
						fill: $color_white;
					}
				}

				a.login, a.search, a.toggle {
					color: $color_white;
					border-color: $color_white;

					svg {
						path {
							fill: $color_white;
						}
					}
				}
			}

			.menu {
				background-color: $color_black;
				background-image: none;
				border-top: 1px solid $color_white;

				.container {
					.custom > div {
						background-color: $color_black;
						background-image: none;

						&.i_have {
							border-bottom: 1px solid $color_white;

							@media (min-width: 36em) {
								border-right: 1px solid $color_white;
								border-bottom: 0;
							}

							@media (min-width: $breakpoint_to_flex_row_home) {
								border-bottom: 1px solid $color_white;
							}

							@media (min-width: $breakpoint_to_flex_row_interior) {
								border-bottom: 1px solid $color_white;
							}
						}

						&.i_am {
							@media (min-width: $breakpoint_to_flex_row_home) {
								border-bottom: 1px solid $color_white;
							}

							@media (min-width: $breakpoint_to_flex_row_interior) {
								border-right: 1px solid $color_white;
								border-bottom: 0;
							}
						}

						.img_background {
							display: none;
						}
					}

					.standard {
						border-color: $color_white !important;

						a:hover {
							color: $color_white;
						}
					}
				}

				.request_quote {
					background-image: none;

					@media (min-width: $breakpoint_to_flex_row_home) {
						justify-content: center;

						.button {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}