.bloq_heading {
	padding-top: 2rem;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	text-align: center;

	div {
		max-width: 100%;
		color: $color_secondary_purple;
		@include scale(font-size, 1.5rem, 20em, 2rem, 40em);
	}

	p {
		max-width: 100%;
		margin-top: 0;
		margin-bottom: 2rem;
	}
}

body[data-contrast-mode=on] {
	.bloq_heading div {
		color: $color_white;
	}
}

.bloq {
	//margin-bottom: $bloq_media_spacing;

	a {
		svg {
			display: inline-block;
			width: 14px;
			height: 10px;
			margin-left: .625rem;

			path {
				fill: currentColor;
			}
		}
	}

	&.media {
		&.one .media {
			max-width: 40rem;
			margin-right: auto;
			margin-left: auto;
		}

		&.two > *:first-child {
			margin-bottom: $bloq_media_spacing;
		}

		&.content {
			margin-bottom: 0;
		}

		&.two,
		&.three,
		&.four {
			padding-right: 1.5rem;
			padding-left: 1.5rem;
		}

		@media (min-width: 40rem) {
			&.content,
			&.two {
				display: flex;
				align-items: flex-start;
			}

			&.right,
			&.Right {
				flex-direction: row-reverse;

				.content {
					padding-left: 1.5rem;
				}
			}

			&.left,
			&.Left {
				.content {
					padding-right: 1.5rem;
				}
			}

			&.two > * {
				flex: 1 1 auto;
				width: 50%;
			}

			&.content > .media {
				flex: 1 1 36%;
				min-width: 36%;
				width: 36%;
				margin-top: $bloq_media_spacing;
			}

			&.content > .content {
				flex: 1 1 auto;
				display: flex;
				align-items: center;
				width: 100%;
				max-width: 100%;

				h1, h2, h3, h4, h5, h6 {
					&:first-child {
						margin-top: 0;
					}
				}

				& > * {
					width: 100%;
					max-width: 100%;
				}
			}

			&.two > *:first-child {
				margin-right: $bloq_media_spacing;
				margin-bottom: 0;
			}
		}

		&.three > *:nth-child(1),
		&.three > *:nth-child(2) {
			margin-bottom: $bloq_media_spacing;
		}

		@media (min-width: 30rem) {
			&.three {
				display: flex;
				flex-wrap: wrap;
			}

			&.three > *:nth-child(1) {
				flex: 1 1 100%;
			}

			&.three > *:nth-child(2),
			&.three > *:nth-child(3) {
				flex-grow: 1;
				flex-shrink: 1;
				flex-basis: calc((100% - #{$bloq_media_spacing}) / 2.0001);
				max-width: calc((100% - #{$bloq_media_spacing}) / 2.0001);
				margin-bottom: 0;
			}

			&.three > *:nth-child(2) {
				margin-right: $bloq_media_spacing;
			}

			@media (min-width: 45rem) {
				&.three {
					flex-wrap: nowrap;
				}

				&.three > *:nth-child(1),
				&.three > *:nth-child(2),
				&.three > *:nth-child(3) {
					flex-grow: 1;
					flex-shrink: 1;
					flex-basis: calc((100% - #{$bloq_media_spacing * 2}) / 3.0001);
					max-width: calc((100% - #{$bloq_media_spacing * 2}) / 3.0001);
					margin-bottom: 0;
				}

				&.three > *:nth-child(1) {
					margin-right: $bloq_media_spacing;
				}
			}
		}

		&.four > *:nth-child(1),
		&.four > *:nth-child(2),
		&.four > *:nth-child(3) {
			margin-bottom: $bloq_media_spacing;
		}

		@media (min-width: 30rem) {
			&.four {
				display: flex;
				flex-wrap: wrap;
			}

			&.four > * {
				flex-grow: 1;
				flex-shrink: 1;
				flex-basis: calc((100% - #{$bloq_media_spacing}) / 2.0001);
				max-width: calc((100% - #{$bloq_media_spacing}) / 2.0001);
			}

			&.four > *:nth-child(1),
			&.four > *:nth-child(3) {
				margin-right: $bloq_media_spacing;
			}

			&.four > *:nth-child(3) {
				margin-bottom: 0;
			}

			@media (min-width: 45rem) {
				&.four {
					flex-wrap: nowrap;
				}

				&.four > * {
					flex-grow: 1;
					flex-shrink: 1;
					flex-basis: calc((100% - #{$bloq_media_spacing * 3}) / 4.0001);
					max-width: calc((100% - #{$bloq_media_spacing * 3}) / 4.0001);
				}

				&.four > *:nth-child(2) {
					margin-right: $bloq_media_spacing;
				}

				&.four > *:nth-child(4) {
					margin-right: 0;
				}

				&.four > *:nth-child(1),
				&.four > *:nth-child(2),
				&.four > *:nth-child(3),
				&.four > *:nth-child(4) {
					margin-bottom: 0;
				}
			}
		}

		div header {
			text-align: center;

			div {
				padding-top: 1rem;
				padding-bottom: .3125rem;
				color: $color_secondary_purple;
				@include scale(font-size, 1rem, 20em, 1.375rem, 40em);
			}

			p {
				margin-top: 0;
			}
		}
	}

	&.content:not(.media) {
		ul, ol {
			&.list_column {
				@media (min-width: 50em) {
					column-count: 2;

					li {
						break-inside: avoid;
						list-style-position: inside;
					}
				}
			}
		}
	}

	a.media {
		position: relative;
		display: block;
		color: $bloq_overlay_text_color;
		z-index: 1;

		&.video {
			position: relative;
		}

		&:hover {
			color: $bloq_overlay_text_color_hover;
		}

		figure {
			figcaption {
				@extend %center;
				width: 100%;
				margin: 0;
				padding: 1.5rem;
				text-align: center;
				text-shadow: 0 0 .5rem rgba($color_black, .6);
				z-index: 3;
			}

			svg.play {
				fill: $bloq_icon_color;
				@extend %center;
				width: 10%;
				min-width: 2.5rem;
				min-height: 2.5rem;
				max-width: 6rem;
				max-height: 6rem;
				filter: drop-shadow(0 0 1rem rgba($bloq_icon_shadow_color, .6));
				z-index: 3;
			}
		}

		&.video {
			figure svg.play {
				@extend %center;
				width: 100%;
				max-width: 5rem;
				height: 100%;
				max-height: 5rem;
				margin: 0;
				filter: drop-shadow(0 0 1rem rgba(0, 0, 0, .6));

				path {
					fill: $color_white;
					transition: all .25s;
				}
			}

			&:hover figure svg path {
				fill: $color_secondary_orange;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		//justify-content: center;
		align-items: center;
		padding: $bloq_paragraph_spacing;
		text-align: center;

		@media (min-width: 40rem) {
			padding: $bloq_paragraph_spacing $bloq_media_spacing;
			text-align: left;
		}

		ul, ol {
			li {
				text-align: left;
			}
		}

		h2 {
			max-width: 100%;
			margin-top: 0;
			margin-bottom: 0;
		}

		p {
			max-width: 100%;
		}
	}
}

.has_blended_wave {
	.bloq.media.content {
		padding-bottom: 14.435114503817vw;
	}

	@media (min-width: 40rem) {
		.bloq.media.content {
			align-items: stretch;
			padding-bottom: 0;

			& > .media {
				figure.aspect {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					height: 100%;
					padding-bottom: 0;
				}
			}

			& > .content {
				padding-bottom: 14.435114503817vw;
			}
		}
	}
}

.has_member_discounts {
	.bloq.media.content > .content ul {
		list-style: none;
		margin: 0 0 1rem 0;
		padding: 0;

		li {
			display: block;
			padding: .625rem 0;
			font-weight: 600;
			border-bottom: 1px solid $color_mercury;
		}

		& + a {

		}
	}
}

body[data-contrast-mode=on] {
	.bloq.media div header div {
		color: $color_white;
	}
}

$breakpoint_tookit_to_two_columns		: 36em;
$breakpoint_tookit_to_three_columns		: 54em;
.bloq.toolkit {
	width: 100%;
	max-width: 68rem;
	margin-right: auto;
	margin-left: auto;

	header {
		text-align: center;

		div {
			margin-bottom: 1rem;
			color: $color_secondary_purple;
			font-size: 2rem;
			line-height: 1.25;
			letter-spacing: .063125em;
		}

		p {
			margin: .25rem 0;
			color: $color_secondary_gray;
			line-height: 1.5;
			letter-spacing: .031875em;
		}
	}

	& > div {
		margin-top: 3rem;

		@media (min-width: $breakpoint_tookit_to_two_columns) {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

		& > .icon {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			margin: 0;
			margin-bottom: 1.5rem;
			padding: 2.5rem 1.5rem;
			color: $color_secondary_gray;
			border-radius: .5rem;
			background: $color_white;
			transition: all .25s;

			@media (min-width: $breakpoint_tookit_to_two_columns) {
				flex: 1 1 auto;
				width: calc((100% - 1.75rem) / 2.0001);

				&:nth-of-type(1) {
					margin-right: 1.75rem;
				}
			}

			@media (min-width: $breakpoint_tookit_to_three_columns) {
				width: calc((100% - 3.5rem) / 3.0001);

				&:nth-of-type(2n + 1) {
					margin-right: 0;
				}

				&:not(:nth-of-type(3n + 3)) {
					margin-right: 1.75rem;
				}
			}

			svg.play {
				display: block;
				width: 5.625rem;
				height: 5.625rem;
				margin-right: auto;
				margin-bottom: 1.125rem;
				margin-left: auto;

				path,
				circle {
					fill: rgba($color_secondary_purple, .8);
					transition: all .25s;
				}
			}

			span {
				max-width: 100%;
				color: $color_secondary_gray;
				font-weight: 600;
				font-size: 1.125rem;
				line-height: 1.2;
				letter-spacing: .035625em;
				text-align: center;
			}

			p {
				max-width: 100%;
				margin-bottom: 0;
				font-weight: 500;
				text-align: center;
			}
		}

		a.icon:hover {
			//color: $color_white;
			//background-color: rgba($color_secondary_purple, .8);

			svg.play {
				path,
				circle {
					fill: $color_secondary_orange;
				}
			}

			span {
				//color: $color_white;
			}
		}
	}

	&:not(.no_border) .icon {
		border: 1px solid $color_alto;
	}
}

body[data-contrast-mode=on] {
	.bloq.toolkit header div,
	.bloq.toolkit header p {
		color: $color_white;
	}

	.bloq.toolkit > div > .icon {
		background-color: $color_black;
		border-color: $color_white;

		p,
		span {
			color: $color_white;
		}

		svg {
			path,
			circle {
				fill: $color_white;
			}
		}
	}
}

.toolkit_wrapper {
	.max_content_width {
		padding: 1.5rem !important;
	}

	& + .toolkit_wrapper {
		margin-top: -1.5rem !important;
		padding-top: 0 !important;

		.max_content_width {
			padding-top: 0 !important;

			.bloq.toolkit div {
				margin-top: 0 !important;
			}
		}

		// @media (min-width: $breakpoint_tookit_to_two_columns) {
		// 	margin-top: -14rem;
		// }
	}
}

.bloq.health_tip {
	margin-bottom: 0;
	padding: 2rem 0;
	//background-image: url(/assets/images/health-tip-background.svg);
	background-position: 50% 100%;
	background-repeat: no-repeat;
	background-attachment: scroll;

	.label {
		color: $color_secondary_gray;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
	}

	p {
		max-width: 46.875rem;
		margin: 0 auto;
		padding: 4rem 1.5rem;
		color: $color_secondary_purple;
		font-weight: 400;
		@include scale(font-size, 1.5rem, 20em, 3rem, 40em);
		line-height: 1.35417em;
		text-align: center;
	}
}

body[data-contrast-mode=on] {
	.bloq.health_tip {
		background-color: $color_black;

		.label,
		p {
			color: $color_white;
		}
	}
}

.bloq.faq {
	padding-bottom: 3rem;
}

.bloq.callout {
	padding: 2.5rem 1.5rem;
	color: $color_white;
	text-align: center;
	background-color: $color_secondary_orange;

	.max_content_width {
		@media (min-width: 50em) {
			display: flex;
			align-items: center;
		}

		div {
			margin-bottom: 3rem;
			font-size: 1.125rem;
			letter-spacing: .03167em;

			@media (min-width: 50em) {
				flex: 1 1 100%;
				margin-right: 3rem;
				margin-bottom: 0;
				text-align: left;
			}

			header {
				@include scale(font-size, 1.5rem, 20em, 2rem, 40em);
				line-height: 1.2;
				letter-spacing: .03156em;
			}

			p {
				margin-top: .5rem;

				@media (min-width: 50em) {
					margin-bottom: 0;
				}
			}
		}

		.button {
			color: $color_secondary_orange;
			background-color: $color_white;
			border-color: $color_white;

			&:hover {
				color: $color_white;
				background-color: $color_secondary_purple;
				border-color: $color_secondary_purple;
			}

			@media (min-width: 50em) {
				flex: 0 0 auto;
				white-space: nowrap;
			}
		}
	}
}

body[data-contrast-mode=on] {
	.bloq.callout {
		background-color: $color_black;
		border-top: 1px solid $color_white;
		border-bottom: 1px solid $color_white;

		.button {
			color: $color_black;

			&:hover {
				color: $color_black;
				background-color: $color_white;
				border-color: $color_white;
			}
		}
	}
}

.bloq.blog_listing .col,
.bloq .blog_listing .col {
	margin-bottom: 2.1875rem;
}

.bloq.provider_map {
	padding: 0 1.5rem;

	.max_content_width {
		@media (min-width: 50em) {
			display: flex;
		}
	}

	.blog_listing {
		padding-right: 0;
		padding-left: 0;
		background-color: transparent;

		@media (min-width: 50em) {
			flex: 1 1 50%;
			margin-right: 4rem;
		}

		.col {
			width: 100% !important;
			max-width: 100% !important;
			min-height: 18rem;
		}
	}

	.svg_map {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (min-width: 50em) {
			flex: 1 1 50%;
		}

		svg,
		img {
			display: block;
			width: 100%;
			max-width: 100%;
			height: auto !important;
		}

		.map_label {
			@include scale(margin-top, 1rem, 20em, 4rem, 50em);
			padding-bottom: 2rem;
			color: $color_secondary_purple;
			font-weight: 600;
			font-size: 1.25rem;
			text-align: center;
		}
	}
}

section[class^=Wave].blend_Top + .bloq.provider_map,
section[class^=Wave].blend_Top + a + .bloq.provider_map {
	background-color: #fafafa;
}

body[data-contrast-mode='on'] {
	section[class^=Wave].blend_Top + .bloq.provider_map,
	section[class^=Wave].blend_Top + a + .bloq.provider_map {
		background-color: black;
	}
}

.bloq.staff_listing {
	padding: 0;

	h2 {
		margin-top: 0;
	}
}

.bloq.lead_generation {
	padding: 0 1.5rem;

	@media (min-width: 50em) {
		label {
			float: left;
			width: 48%;

			&:nth-of-type(odd) {
				clear: both;
				margin-right: 4%;
			}
		}

		.g-recaptcha {
			float: left;
			margin-top: 3.1875rem;
		}
	}
}

.full-bloq {
	width: 100%;
	padding: $bloq_media_spacing;
	margin: 1rem auto 10rem auto;

	h1 {
		font-weight:400;
	}

	.media.three {
		max-width: 95%;
		margin: 1.5rem 0;

		> div {
			margin-right: 3rem;
		}

		figure {
			padding-bottom: 100%;

			.aspect img {
				object-fit: cover;
				position: absolute;
				height: 100%;
				width: 100%;

			}
		}

		a header {
			font-weight: 500;
		}

	}

	.wellness_health_links {
		padding-top: 2rem;

		> p a {
			font-weight: 500;
		}
	}
}

.bloq {
	max-width: 73rem;
	margin-right: auto;
	margin-left: auto;
	padding: 0 1.5rem;
	margin-bottom: $bloq_media_spacing;

	&.media {
		&.one.full_bleed {
			width: 100vw;
			margin-left: 50%;
			transform: translateX(-50vw);
		}

		&.two > *:first-child {
			margin-bottom: $bloq_media_spacing;
		}

		@media (min-width: 40em) {
			&.content,
			&.two {
				@include flexbox();
				@include align-items(center);
			}

			&.right,
			&.Right {
				flex-direction: row-reverse;
			}

			&.content > *,
			&.two > * {
				flex: 1 1 auto;
				width: 50%;
			}

			&.content > .content {
				display: flex;
				align-items: center;

				& > * {
					max-width: 100%;
				}
			}

			&.two > *:first-child {
				margin-right: $bloq_media_spacing;
				margin-bottom: 0;
			}
		}

		&.three > *:nth-child(1),
		&.three > *:nth-child(2) {
			margin-bottom: $bloq_media_spacing;
		}

		@media (min-width: 30em) {
			&.three {
				display: flex;
				flex-wrap: wrap;
			}

			&.three > *:nth-child(1) {
				flex: 1 1 100%;
			}

			&.three > *:nth-child(2),
			&.three > *:nth-child(3) {
				flex-grow: 1;
				flex-shrink: 1;
				flex-basis: calc((100% - #{$bloq_media_spacing}) / 2.0001);
				max-width: calc((100% - #{$bloq_media_spacing}) / 2.0001);
				margin-bottom: 0;
			}

			&.three > *:nth-child(2) {
				margin-right: $bloq_media_spacing;
			}

			@media (min-width: 45em) {
				&.three {
					flex-wrap: nowrap;
				}

				&.three > *:nth-child(1),
				&.three > *:nth-child(2),
				&.three > *:nth-child(3) {
					flex-grow: 1;
					flex-shrink: 1;
					flex-basis: calc((100% - #{$bloq_media_spacing * 2}) / 3.0001);
					max-width: calc((100% - #{$bloq_media_spacing * 2}) / 3.0001);
					margin-bottom: 0;
				}

				&.three > *:nth-child(1) {
					margin-right: $bloq_media_spacing;
				}
			}
		}

		&.four > *:nth-child(1),
		&.four > *:nth-child(2),
		&.four > *:nth-child(3) {
			margin-bottom: $bloq_media_spacing;
		}

		@media (min-width: 30em) {
			&.four {
				display: flex;
				flex-wrap: wrap;
			}

			&.four > * {
				flex-grow: 1;
				flex-shrink: 1;
				flex-basis: calc((100% - #{$bloq_media_spacing}) / 2.0001);
				max-width: calc((100% - #{$bloq_media_spacing}) / 2.0001);
			}

			&.four > *:nth-child(1),
			&.four > *:nth-child(3) {
				margin-right: $bloq_media_spacing;
			}

			&.four > *:nth-child(3) {
				margin-bottom: 0;
			}

			@media (min-width: 45em) {
				&.four {
					flex-wrap: nowrap;
				}

				&.four > * {
					flex-grow: 1;
					flex-shrink: 1;
					flex-basis: calc((100% - #{$bloq_media_spacing * 3}) / 4.0001);
					max-width: calc((100% - #{$bloq_media_spacing * 3}) / 4.0001);
				}

				&.four > *:nth-child(2) {
					margin-right: $bloq_media_spacing;
				}

				&.four > *:nth-child(4) {
					margin-right: 0;
				}

				&.four > *:nth-child(1),
				&.four > *:nth-child(2),
				&.four > *:nth-child(3),
				&.four > *:nth-child(4) {
					margin-bottom: 0;
				}
			}
		}

		div {
			header {
				text-align: center;

				div {
					padding-top: 1rem;
					padding-bottom: .3125rem;
					color: $color_comet;
					@include scale(font-size, 1rem, 20em, 1.375rem, 40em);
					transition: all .25s;
				}

				p {
					margin-top: 0;
				}
			}

			a:hover,
			a:focus {
				header div {
					color: $color_carrot_orange;
				}
			}
		}
	}

	& > a.media {
		position: relative;
		display: block;
		color: $bloq_overlay_text_color;
		z-index: 1;

		&.video {
			position: relative;
		}

		&:after {
			content: '';
			@extend %fullcoverage;
			transition: all .25s;
			background-image: linear-gradient(to bottom, rgba($bloq_overlay_color, .4) 0%, rgba($bloq_overlay_color, .8) 100%);
			z-index: 2;
			opacity: 0;
		}

		&:hover,
		&:focus {
			color: $bloq_overlay_text_color_hover;
		}

		&:hover:after,
		&:focus:after {
			opacity: .375;
		}

		figure {
			figcaption {
				@extend %center;
				width: 100%;
				margin: 0;
				padding: 1.5rem;
				text-align: center;
				text-shadow: 0 0 .5rem rgba($color_black, .6);
				z-index: 3;
			}

			svg {
				fill: $bloq_icon_color;
				@extend %center;
				width: 10%;
				min-width: 2.5rem;
				min-height: 2.5rem;
				max-width: 6rem;
				max-height: 6rem;
				filter: drop-shadow(0 0 1rem rgba($bloq_icon_shadow_color, .6));
				z-index: 3;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: $bloq_paragraph_spacing;
	}

	.icon-full svg {
		width: 100%;
		height: 100%;
		color: $color_jacarta;
		margin: 0;
	}

	.field-select-dropdown {
		//&::before {
		//	content: url('../../svgs/select-dropdown-expand.svg');
		//	position: absolute;
		//	top: 50%;
		//	right: .875rem;
		//	width: .625rem;
		//	height: 1.375rem;
		//	z-index: 1;
		//	transform: translateY(-50%);
		//}
	}

	.field-wrapper-checkbox {
		flex: 0 0 auto;
		order: 1;
		line-height: 0;

		.checkbox {
			position: relative;
			display: inline-block;
			width: 1.5625rem;
			height: 1.5625rem;
			margin-right: .875rem;
			border: $input_border_thickness solid #858585;
			border-radius: $input_border_radius;
			cursor: pointer;
			transition: all .25s;

			&::after {
				content: url('../../svgs/checkbox-radio-selected.svg');
				position: absolute;
				top: 50%;
				left: 50%;
				width: .6875rem;
				height: .5625rem;
				opacity: 0;
				transform: translate(-50%, -50%);
				transition: all .25s;
			}
		}

		input[type=checkbox] {
			position: absolute;
			opacity: 0;
			z-index: -1;

			&:checked + .checkbox {
				background-color: $color_primary_green;
				border-color: $color_primary_green;

				&::after {
					opacity: 1;
				}
			}

			&:disabled + .checkbox {
				background-color: #eee;
				border-color: #eee;

				&::after {
					content: url('../../svgs/checkbox-radio-disabled.svg');
					opacity: 1;
				}
			}
		}

		&.error:after {
			display: none;
		}

		&:hover,
		&.hover {
			input[type=checkbox]:not(:checked):not(:disabled) + .checkbox {
				background-color: #eee;
				border-color: #eee;

				&::after {
					content: url('../../svgs/checkbox-radio-hover.svg');
					opacity: 1;
					left: 45%;
				}
			}
		}
	}
}

$breakpoint_tookit_to_two_columns		: 36em;
$breakpoint_tookit_to_three_columns		: 54em;
.bloq.toolkit {
	width: 100%;
	max-width: 68rem;
	margin-right: auto;
	margin-left: auto;

	header {
		text-align: center;

		div {
			margin-bottom: 1rem;
			color: $color_comet;
			font-size: 2rem;
			line-height: 1.25;
			letter-spacing: .063125em;
		}

		p {
			margin: .25rem 0;
			color: $color_nevada;
			line-height: 1.5;
			letter-spacing: .031875em;
		}
	}

	& > div {
		margin-top: 3rem;

		@media (min-width: $breakpoint_tookit_to_two_columns) {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

		& > .icon {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			margin: 0;
			margin-bottom: 1.5rem;
			padding: 2.5rem 1.5rem;
			color: $color_nevada;
			border-radius: .5rem;
			background: $color_white;
			transition: all .25s;

			@media (min-width: $breakpoint_tookit_to_two_columns) {
				flex: 1 1 auto;
				width: calc((100% - 1.75rem) / 2.0001);

				&:nth-of-type(1) {
					margin-right: 1.75rem;
				}
			}

			@media (min-width: $breakpoint_tookit_to_three_columns) {
				width: calc((100% - 3.5rem) / 3.0001);

				&:nth-of-type(2n + 1) {
					margin-right: 0;
				}

				&:not(:nth-of-type(3n + 3)) {
					margin-right: 1.75rem;
				}
			}

			svg {
				display: block;
				width: 5.625rem;
				height: 5.625rem;
				margin-right: auto;
				margin-bottom: 1.125rem;
				margin-left: auto;

				path,
				circle {
					fill: rgba($color_comet, .8);
					transition: all .25s;
				}
			}

			span {
				max-width: 100%;
				color: $color_nevada;
				font-weight: 600;
				font-size: 1.125rem;
				line-height: 1.2;
				letter-spacing: .035625em;
				text-align: center;
			}

			p {
				max-width: 100%;
				margin-bottom: 0;
				font-weight: 500;
				text-align: center;
			}
		}

		a.icon:hover {
			//color: $color_white;
			//background-color: rgba($color_comet, .8);

			svg {
				path,
				circle {
					fill: $color_carrot_orange;
				}
			}

			span {
				//color: $color_white;
			}
		}
	}

	&:not(.no_border) .icon {
		border: 1px solid $color_alto;
	}
}

body[data-contrast-mode=on] {
	.bloq.toolkit header div,
	.bloq.toolkit header p {
		color: $color_white;
	}

	.bloq.toolkit > div > .icon {
		background-color: $color_black;
		border-color: $color_white;

		p,
		span {
			color: $color_white;
		}

		svg {
			path,
			circle {
				fill: $color_white;
			}
		}
	}
}

.toolkit_wrapper {
	.max_content_width {
		padding: 1.5rem !important;
	}

	& + .toolkit_wrapper {
		margin-top: -1.5rem !important;
		padding-top: 0 !important;

		.max_content_width {
			padding-top: 0 !important;

			.bloq.toolkit div {
				margin-top: 0 !important;
			}
		}

		// @media (min-width: $breakpoint_tookit_to_two_columns) {
		// 	margin-top: -14rem;
		// }
	}
}

.bloq.health_tip {
	margin-bottom: 0;
	padding: 2rem 0;
	//background-image: url("/assets/images/health-tip-background.svg");
	background-position: 50% 100%;
	background-repeat: no-repeat;
	background-attachment: scroll;

	.label {
		color: $color_nevada;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
	}

	p {
		max-width: 46.875rem;
		margin: 0 auto;
		padding: 4rem 1.5rem;
		color: $color_comet;
		font-weight: 400;
		@include scale(font-size, 1.5rem, 20em, 3rem, 40em);
		line-height: 1.35417em;
		text-align: center;
	}
}

body[data-contrast-mode=on] {
	.bloq.health_tip {
		background-color: $color_black;

		.label,
		p {
			color: $color_white;
		}
	}
}

.bloq.faq {
	padding-bottom: 3rem;
}

.bloq.callout {
	padding: 2.5rem 1.5rem;
	color: $color_white;
	text-align: center;
	background-color: $color_carrot_orange;

	.max_content_width {
		@media (min-width: 60em) {
			display: flex;
			align-items: center;
		}

		div {
			margin-bottom: 3rem;
			font-size: 1.125rem;
			letter-spacing: .03167em;

			@media (min-width: 60em) {
				flex: 1 1 100%;
				margin-right: 3rem;
				margin-bottom: 0;
				text-align: left;
			}

			header {
				@include scale(font-size, 1.5rem, 20em, 2rem, 40em);
				line-height: 1.2;
				letter-spacing: .03156em;
			}

			p {
				margin-top: .5rem;

				@media (min-width: 60em) {
					margin-bottom: 0;
				}
			}
		}

		.button {
			margin-top: 0;
			color: $color_carrot_orange;
			background-color: $color_white;
			border-color: $color_white;

			&:hover {
				color: $color_white;
				background-color: $color_comet;
				border-color: $color_comet;
			}

			@media (min-width: 60em) {
				flex: 0 0 auto;
				white-space: nowrap;
			}
		}
	}
}

body[data-contrast-mode=on] {
	.bloq.callout {
		background-color: $color_black;
		border-top: 1px solid $color_white;
		border-bottom: 1px solid $color_white;

		.button {
			color: $color_black;

			&:hover {
				color: $color_black;
				background-color: $color_white;
				border-color: $color_white;
			}
		}
	}
}

.bloq.blog_listing .col,
.bloq .blog_listing .col {
	margin-bottom: 2.1875rem;
}

.bloq.provider_map {
	padding: 0 1.5rem;

	.max_content_width {
		@media (min-width: 50em) {
			display: flex;
		}
	}

	.blog_listing {
		padding-right: 0;
		padding-left: 0;
		background-color: transparent;

		@media (min-width: 50em) {
			flex :1 1 50%;
			margin-right: 4rem;
		}

		.col {
			width: 100% !important;
			max-width: 100% !important;
			min-height: 18rem;
		}
	}

	.svg_map {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (min-width: 50em) {
			flex :1 1 50%;
		}

		svg,
		img {
			display: block;
			width: 100%;
			max-width: 100%;
			height: auto !important;
		}

		.map_label {
			@include scale(margin-top, 1rem, 20em, 4rem, 50em);
			padding-bottom: 2rem;
			color: $color_comet;
			font-weight: 600;
			font-size: 1.25rem;
			text-align: center;
		}
	}
}

section[class^=Wave].blend_Top + .bloq.provider_map,
section[class^=Wave].blend_Top + a + .bloq.provider_map {
	background-color: $color_alabaster;
}

body[data-contrast-mode='on'] {
	section[class^=Wave].blend_Top + .bloq.provider_map,
	section[class^=Wave].blend_Top + a + .bloq.provider_map {
		background-color: $color_black;
	}
}

.bloq.staff_listing {
	padding: 0;

	h2 {
		margin-top: 0;
	}
}

div[id^=bloq-tab] h3 {
	padding-top: 10px;
	padding-bottom: 10px;
}