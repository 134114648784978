.slab {
	&.content {
		max-width: 80ch;
		margin-right: auto;
		margin-left: auto;
		padding: 0 1.5rem;

		img {
			display: block;
			width: 90% !important;
			height: auto !important;
			margin: 2em auto;
		}
	}

	&.content-image {
		$breakpoint_to_flex : 60em;
		max-width: 60rem;
		margin-right: auto;
		margin-left: auto;
		padding: 0 1.5rem;

		@media (min-width: $breakpoint_to_flex) {
			display: flex;
			justify-content: space-between;

			&.img-left {
				a {
					margin-right: 2rem;
				}
			}

			&.img-right {
				a {
					order: 2;
					margin-left: 2rem;
				}

				.content {
					order: 1;
				}
			}
		}

		a {
			position: relative;

			@media (min-width: $breakpoint_to_flex) {
				flex: 1 1 50%;
			}

			figure {
				@media (min-width: $breakpoint_to_flex) {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					overflow: hidden;
				}

				> svg {
					display: none;

					@media (min-width: $breakpoint_to_flex) {
						display: block;
						position: absolute;
						top: 50%;
						left: 50%;
						width: 100%;
						height: 100%;
						transform: translate(-50%, -50%);
					}
				}

				> img {
					width: 100%;
					height: auto;

					@media (min-width: $breakpoint_to_flex) {
						display: none;
					}
				}

				@media (min-width: $breakpoint_to_flex) {
					@supports ((object-fit: cover) or (object-fit: contain)) {
						> svg {
							display: none;
						}

						> img {
							object-fit: cover;
							position: absolute;
							top: 50%;
							left: 50%;
							display: block;
							width: 100%;
							height: 100%;
							transform: translate(-50%, -50%);

							&.contain {
								object-fit: contain;
							}
						}
					}
				}
			}
		}

		.content {
			@media (min-width: $breakpoint_to_flex) {
				flex: 1 1 50%;
			}
		}
	}

	&.side-media {
		display: flex;
		flex-wrap: wrap;
		max-width: 61.5rem;
		margin-right: auto;
		margin-left: auto;
		padding: 0 1.5rem;

		> a {
			flex: 1 1 auto;
			min-width: 12rem;
			max-width: 24rem;
			margin: .75rem;
		}
	}

	&.full-bleed {
		height: 37.5rem;

		figure {
			height: 100%;
			padding-bottom: 0;
		}
	}

	&.form {
		max-width: 60rem;
		margin-right: auto;
		margin-left: auto;
		padding: 0 1.5rem;
	}
}