@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(270deg);
	}
}

@keyframes colors {
	0% {
		stroke: $loading_spinner_color1;
	}

	25% {
		stroke: $loading_spinner_color2;
	}

	50% {
		stroke: $loading_spinner_color3;
	}

	75% {
		stroke: $loading_spinner_color4;
	}

	100% {
		stroke: $loading_spinner_color1;
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: $loading_spinner_offset;
	}

	50% {
		stroke-dashoffset: $loading_spinner_offset / 4;
		transform: rotate(135deg);
	}

	100% {
		stroke-dashoffset: $loading_spinner_offset;
		transform: rotate(450deg);
	}
}