.header,
.header-icon,
.header-icon.left {
	.heading {
		@extend h5;
	}

	a {
		svg {
			width: .875rem;
			height: .625rem;
			margin-left: .5rem;

			path {
				fill: currentColor;
			}
		}
	}
}

.header-icon {
	header {
		display: flex;
		align-items: center;

		.icon {
			flex: 1 1 1.875rem;
			min-width: 1.875rem;
			max-width: 1.875rem;
			margin-right: 1.25rem;

			svg {
				display: block;
				width: 100%;
			}
		}

		.text {
			flex: 1 1 100%;

			.heading {
				margin-top: 0;
				margin-bottom: 0;
			}

			.category {
				color: #6a737b;
				font-weight: 600;
				font-size: .875rem;
			}
		}
	}
}

.header-icon.left {
	display: flex;
	align-items: flex-start;

	.icon {
		flex: 1 1 4.0625rem;
		min-width: 4.0625rem;
		max-width: 4.0625rem;
		margin-right: 1.25rem;

		svg {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.text {
		flex: 1 1 100%;

		.heading {
			margin-top: 0;
			margin-bottom: 0;

			& + p {
				margin-top: .125rem;
			}
		}
	}
}