.accordion {
	.title {
		position: relative;
		display: block;
		padding: 1rem 3rem 1rem .125rem;
		color: #5d5681;
		font-weight: 400;
		font-size: 1.125rem;
		line-height: 1.5;
		border-bottom: 1px solid #e5e5e5;
		cursor: pointer;

		span:not(.svg) {
			&:first-of-type {
				color: $color_secondary_purple;
				font-weight: 400;
				font-size: 1.125rem;
			}

			&:last-of-type {
				position: absolute;
				top: 50%;
				right: .125rem;
				margin: 0;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
				padding: 1rem .5rem;

				> svg {
					position: absolute;
					top: 50%;
					left: 50%;
					transition: all .25s;
					transform: translate(-50%, -50%);
					margin-left: 0;

					&:first-of-type {
						height: .875rem;
					}

					&:last-of-type {
						width: 1.125rem;
						height: 1.125rem;
						opacity: 0;

						path {
							fill: $color_secondary_orange;
						}
					}
				}

				span.svg {
					position: absolute;

					> svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transition: all .25s;
						transform: translate(-50%, -50%);
					}

					&:first-of-type {
						> svg {
							height: .875rem;
						}
					}

					&:last-of-type {
						> svg {

							width: 1.125rem;
							height: 1.125rem;
							opacity: 0;

							path {
								fill: $color_secondary_orange;
							}
						}
					}
				}
			}
		}

		&:hover {
			color: $color_carrot_orange !important;
		}
	}

	.content {
		display: none;
		padding: 1.25rem 1.75rem;
		border: 1px solid #eaeaea;
		border-top: 0;

		> * {
			width: 100%;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.glossarize {
			margin: 0 !important;
		}

	}


	&.is-open {
		.title span:not(.svg) > svg {
			&:first-of-type {
				opacity: 0;
			}

			&:last-of-type {
				opacity: 1;
			}
		}

		.title span:not(.svg):last-of-type span.svg {
			&:first-of-type {
				 > svg {
					opacity: 0;
				}
			}

			&:last-of-type {
				> svg {
					opacity: 1;
				}
			}
		}

		.content {
			display: block;
			background-color: $color_white;
		}
	}
}
