label.checkbox {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.field-label {
		flex: 1 1 auto;
		order: 2;
		min-width: calc(100% - 3.0625rem);
		width: calc(100% - 3.0625rem);
		max-width: calc(100% - 3.0625rem);
		margin-bottom: 0;
		color: #464648;
		font-size: 1.125rem;
		font-weight: 600;
		cursor: pointer;

		&.error {
			position: relative;
			padding-right: 3rem;

			&::after {
				content: '!';
				position: absolute;
				top: .03125rem;
				right: .75rem;
				width: 1.5rem;
				height: 1.5rem;
				color: $color_white;
				font-weight: 700;
				text-align: center;
				background-color: $color_form_errors;
				border-radius: $input_border_radius;
			}
		}
	}

	.field-wrapper {
		flex: 0 0 auto;
		order: 1;
		line-height: 0;

		.checkbox {
			position: relative;
			display: inline-block;
			width: 1.5625rem;
			height: 1.5625rem;
			margin-right: .875rem;
			border: $input_border_thickness solid #858585;
			border-radius: $input_border_radius;
			cursor: pointer;
			transition: all .25s;

			&::after {
				content: url('../../../svgs/checkbox-radio-selected.svg');
				position: absolute;
				top: 50%;
				left: 50%;
				width: .6875rem;
				height: .5625rem;
				opacity: 0;
				transform: translate(-50%, -50%);
				transition: all .25s;
			}
		}

		input[type=checkbox] {
			position: absolute;
			opacity: 0;
			z-index: -1;

			&:checked + .checkbox {
				background-color: $color_mantis;
				border-color: $color_mantis;

				&::after {
					opacity: 1;
				}
			}

			&:disabled + .checkbox {
				background-color: #eee;
				border-color: #eee;

				&::after {
					content: url('../../../svgs/checkbox-radio-disabled.svg');
					opacity: 1;
				}
			}
		}

		&.error:after {
			display: none;
		}
	}

	.field-error {
		order: 3;
	}

	&:hover,
	&.hover {
		input[type=checkbox]:not(:checked):not(:disabled) + .checkbox {
			background-color: #eee;
			border-color: #eee;

			&::after {
				content: url('../../../svgs/checkbox-radio-hover.svg');
				opacity: 1;
				//left: .5rem;
				//position: absolute;
				//left: .275rem;
				position: absolute;
				bottom: 0rem;
			}
		}
	}
}