.prev-serv-search {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  // @media (min-width: 75em) {
  // 	flex-wrap: nowrap;
  // }

  label {
    flex: 0 1 auto;
    width: auto;

    span {
      color: $color_comet;

      &.clear {
        top: 2.25rem;
      }
    }

    select,
    input {
      width: 11rem !important;
      padding-top: .5rem !important;
      padding-bottom: .5rem !important;
      border-radius: 4px;
    }

    input[name=age] {
      width: 4rem !important;
    }

    select[name=gender] {
      width: 7rem !important;
    }

    select[name=pregnant] {
      width: 5.5rem !important;
    }

    &.select:after {
      top: calc(50% + .25rem);
    }
  }

  .field-wrapper {
    margin-right: .5rem;
  }

  .page-search-export {
    flex: 1 0 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 2rem;

    @media (min-width: 75em) {
      justify-content: space-between;
      align-items: flex-start;
    }

    pagination {
      order: 2;

    }

    .buttons {
      order: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 1.5rem;
      margin-bottom: 2rem;

      @media (min-width: 36em) {
        flex-direction: row;
      }

      @media (min-width: 40em) {
        flex-direction: column;
      }

      @media (min-width: 48em) {
        flex-direction: row;
        justify-content: center;
        width: 100%;
      }


      button {
        margin-top: 0;
        color: white;
        text-transform: none;
        border-radius: 100rem;

        &.search {
          background-color: $color_secondary_orange;
          border-color: $color_secondary_orange;

          &:hover,
          &:focus {
            background-color: darken($color_secondary_orange, 12);
            border-color: darken($color_secondary_orange, 12);
          }
        }

        &.export {
          margin-top: 1.5rem;
          background-color: $color_mantis;
          border-color: $color_mantis;

          @media (min-width: 36em) {
            margin-top: 0;
            margin-left: 1.5rem;
          }

          @media (min-width: 40em) {
            margin-top: 1.5rem;
            margin-left: 0;
          }

          @media (min-width: 48em) {
            margin-top: 0;
            margin-left: 1.5rem;
          }

          &:hover,
          &:focus {
            background-color: darken($color_mantis, 12);
            border-color: darken($color_mantis, 12);
          }
        }
      }
    }
  }
}

.order_eob {
  max-width: 46rem;
  margin: 0 auto;
  text-align: center;

  table tr td:nth-of-type(1) {
    text-align: left;
  }

  .button,
  button {
    float: none !important;
    margin: 2rem auto 4rem auto;
  }
}

.prev_services {
  table-layout: auto;
  font-size: .875rem;
  border: 0;
  border-collapse: initial;
  border-spacing: 0;

  thead {
    display: none;

    @media (min-width: 60em) {
      display: table-header-group;
    }

    tr th {
      position: sticky;
      top: 5rem;
      padding: 1rem .75rem;
      font-weight: 600;
      background-color: $color_white;
      border-top: 1px solid $color_alto;
      border-bottom: 1px solid #dbdbdb;
      z-index: 8999;

      // &:nth-of-type(1) {
      // 	background-color: transparent;
      // 	border: 0;
      // }

      // &:nth-of-type(2) {
      &:nth-of-type(1) {
        border-left: 1px solid $color_alto;
        border-radius: 8px 0 0 0;
      }

      &:last-child {
        border-right: 1px solid $color_alto;
        border-radius: 0 8px 0 0;

        label.checkbox {
          position: relative;
          top: 6px;
          margin: 1rem 0;
        }
      }
    }
  }

  tbody tr {
    &:nth-of-type(1) td:nth-of-type(1) {
      border-top: 1px solid $color_alto;

      @media (min-width: 60em) {
        border-top: 0;
      }
    }

    td {
      display: block;
      background-color: $color_white !important;
      border-right: 1px solid $color_alto;
      border-left: 1px solid $color_alto;

      &:nth-of-type(6) {
        border-bottom: 1px solid $color_alto;
      }

      &:not(:nth-of-type(1)):before {
        content: attr(data-label) ":\0020";
        font-weight: 600;
      }

      @media (min-width: 60em) {
        display: table-cell;
        border-bottom: 1px solid $color_alto;

        &:before {
          display: none;
        }

        &:nth-of-type(1),
        &:nth-of-type(2) {
          border-left: 1px solid $color_alto;
        }

        &:nth-of-type(5) {
          border-right: 1px solid $color_alto;
        }
      }
    }
  }

  label.checkbox {
    position: relative;
    top: 6px;
    display: inline-flex;
    width: auto;
    margin: 0;
    justify-content: center !important;;

    @media (min-width: 60em) {
      top: auto;
      margin: 2rem 0;
      display: flex;
    }

    .checkbox {
      margin: 0 1rem;
      border-radius: 2px;
    }

    input[type=checkbox] {
      &:checked + .checkbox, .ng-not-empty {
        background-color: $color_mantis;
        border-color: $color_mantis;

        &:after {
          left: 50%;
        }
      }
    }
  }

  .detail-link {
    position: relative;
    top: 4px;
    display: inline-block;
    margin: 0 0 0 1rem;

  }

  + pagination {
    display: block;
    margin-top: 2.5rem;
  }
}

.claims_detail {
  @include browser(ie11) {
    .breadcrumbs > span {
      display: inline-flex;
    }
  }

  .show-codes {
    svg {
      position: relative;
      top: -6px;
      margin-left: 1rem;
      transition: all .25s;
    }

    &.active svg {
      transform: rotate(180deg);
    }
  }

  header {
    text-align: center;
  }

  .details-nav {
    text-align: left;

    .back {
      cursor: pointer;
    }
  }

  .details-container {
    text-align: center;
  }

  .address {
    span {
      display: block;

      &:nth-of-type(1) {
        color: $color_comet;
      }
    }
  }

  .reimbursements {
    background-color: $color_white;

    thead {
      display: none;

      @media (min-width: 60em) {
        display: table-header-group;
      }
    }

    tbody {
      display: block;

      @media (min-width: 60em) {
        display: table-row-group;
      }

      tr {
        display: block;

        &:not(:last-of-type) {
          border-bottom: 1px solid $color_alto;
        }

        @media (min-width: 60em) {
          display: table-row;


          &:not(:last-of-type) {
            border-bottom: 0;
          }
        }

        td {
          display: block;

          &:before {
            content: attr(data-label) ":\0020";
            margin-right: .5rem;
            font-weight: 700;
          }

          &:not(:first-of-type):not(:last-of-type) {
            padding-top: 0;
            padding-bottom: 0;
          }

          @media (min-width: 60em) {
            display: table-cell;

            &:not(:first-of-type):not(:last-of-type) {
              padding-top: 1rem;
              padding-bottom: 1rem;
            }

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  .Wave {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
}


.detail-link {


  @include browser(ie11) {
    flex-direction: column;
  }

  > div {
    padding: 2rem;

    span {
      display: block;

      &:nth-of-type(1) {
        color: $color_comet;
      }
    }
  }
}

.multiple_eobs {
  label {
    margin-bottom: 1rem !important;

    &.date_from {
      margin-left: 0 !important;

      @media (min-width: 54em) {
        margin-right: 4% !important;
      }
    }

    &.date_to {
      margin-left: 0 !important;
    }
  }

  .linebreak {
    flex: 1 0 100%;
    min-width: 100%;
  }
}

a.back {
  svg {
    width: .75rem;
    height: .75rem;
    transform: rotate(90deg);

    path {
      transition: all .25s;
      fill: $color_hyperlink;
    }
  }
}


a.back:hover {
  svg {
    path {
      transition: all .25s;
      fill: $color_hyperlink_hover;
    }
  }
}


.preventive_services_form_intro {
  em {
    font-size: 0.8rem;
  }
}

.preventive_services_form_title {
  h4 {
    margin: 0;
  }
}
