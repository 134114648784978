.page_not_found_graphic.site_wrapper {
    min-height: 0px;
}

.page_not_found_graphic {

    svg {
        margin: auto;
        display: block;
    }

    #ball {
        transform: translate3d(323.423077px, 340.781029px, 0px);
    }

    #Cloud_1, #Cloud_2 {
        transform: translate3d(-27.576923px, 10.781029px, 0px);
        animation: cloudmove 120s infinite;
        -webkit-animation: cloudmove 40s infinite;
        -moz-animation: cloudmove 40s infinite;
        -o-animation: cloudmove 40s infinite;
        -ms-animation: cloudmove 40s infinite;
    }

    #Designs:hover #ball {
        animation: ballmove 4s infinite;
        -webkit-animation: ballmove 4s infinite;
        -moz-animation: ballmove 4s infinite;
        -o-animation: ballmove 4s infinite;
        -ms-animation: ballmove 4s infinite;
    }

    /* BALL MOVEMENT*/

        @keyframes ballmove {
            from {transform: scale(1) translate3d(323.423077px, 340.781029px, 0px);}
            to {transform: scale(.6) translate3d(545.423077px, 336.781029px, 0px);}
            0% {opacity: 1;}
            70% {opacity: 1;}
            100% {opacity: 0;}
        }

        @-webkit-keyframes ballmove {
            from {transform: scale(1) translate3d(323.423077px, 340.781029px, 0px);}
            to {transform: scale(.6) translate3d(545.423077px, 336.781029px, 0px);}
            0% {opacity: 1;}
            70% {opacity: 1;}
            100% {opacity: 0;}
        }

        @-moz-keyframes ballmove {
            from {transform: scale(1) translate3d(323.423077px, 340.781029px, 0px);}
            to {transform: scale(.6) translate3d(545.423077px, 336.781029px, 0px);}
            0% {opacity: 1;}
            70% {opacity: 1;}
            100% {opacity: 0;}
        }

        @-o-keyframes ballmove {
            from {transform: scale(1) translate3d(323.423077px, 340.781029px, 0px);}
            to {transform: scale(.6) translate3d(545.423077px, 336.781029px, 0px);}
            0% {opacity: 1;}
            70% {opacity: 1;}
            100% {opacity: 0;}
        }

        @-ms-keyframes ballmove {
            from {transform: scale(1) translate3d(323.423077px, 340.781029px, 0px);}
            to {transform: scale(.6) translate3d(545.423077px, 336.781029px, 0px);}
            0% {opacity: 1;}
            70% {opacity: 1;}
            100% {opacity: 0;}
        }

    /* CLOUD MOVEMENT*/

        @keyframes cloudmove {
            0% {transform: translate3d(-27.576923px, 10.781029px, 0px);}
            50% {transform: translate3d(-57.576923px, 10.781029px, 0px);}
            100% {transform: translate3d(-27.576923px, 10.781029px, 0px);}
        }

        @-webkit-keyframes cloudmove {
            0% {transform: translate3d(-27.576923px, 10.781029px, 0px);}
            50% {transform: translate3d(-57.576923px, 10.781029px, 0px);}
            100% {transform: translate3d(-27.576923px, 10.781029px, 0px);}
        }

        @-moz-keyframes cloudmove {
            0% {transform: translate3d(-27.576923px, 10.781029px, 0px);}
            50% {transform: translate3d(-57.576923px, 10.781029px, 0px);}
            100% {transform: translate3d(-27.576923px, 10.781029px, 0px);}
        }

        @-o-keyframes cloudmove {
            0% {transform: translate3d(-27.576923px, 10.781029px, 0px);}
            50% {transform: translate3d(-57.576923px, 10.781029px, 0px);}
            100% {transform: translate3d(-27.576923px, 10.781029px, 0px);}
        }

        @-ms-keyframes cloudmove {
            0% {transform: translate3d(-27.576923px, 10.781029px, 0px);}
            50% {transform: translate3d(-57.576923px, 10.781029px, 0px);}
            100% {transform: translate3d(-27.576923px, 10.781029px, 0px);}
        }

    }
