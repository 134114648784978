$highlights_margin_columns : 1.5rem;
.highlights {
  margin-right: -#{$highlights_margin_columns / 2};
  margin-left: -#{$highlights_margin_columns / 2};

  @media (min-width: $breakpoint_col4_to2) {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include align-items(flex-start);
    max-width: 80rem;
    margin-right: auto;
    margin-left: auto;
  }

  .highlight {
    @include flex(1 1 auto);
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    width: calc(100% - #{$highlights_margin_columns});
    min-height: 15.875rem;
    margin-right: #{$highlights_margin_columns / 2};
    margin-bottom: 2rem;
    margin-left: #{$highlights_margin_columns / 2};
    padding: 3rem 1.5rem;
    background-color: $color_white;
    border: 1px solid $color_swiss_coffee;
    border-radius: 8px;

    @media (min-width: 36em) {
      @include flex(1 1 auto);
      width: calc((100% - #{$highlights_margin_columns * 2}) / 2.0001);
      max-width: calc((100% - #{$highlights_margin_columns * 2}) / 2.0001);
    }

    @media (min-width: 40em) {
      @include flex(1 1 auto);
      width: calc(100% - #{$highlights_margin_columns});
      max-width: calc(100% - #{$highlights_margin_columns});
    }

    @media (min-width: 50em) {
      @include flex(1 1 auto);
      width: calc((100% - #{$highlights_margin_columns * 2}) / 2.0001);
      max-width: calc((100% - #{$highlights_margin_columns * 2}) / 2.0001);
    }

    @media (min-width: 70em) {
      width: calc((100% - #{$highlights_margin_columns * 4}) / 4.0001);
      max-width: calc((100% - #{$highlights_margin_columns * 4}) / 4.0001);
    }

    header {
      font-size: 1.125rem;
      text-align: center;
      color: $color_dove_gray;
    }

    > div {
      div {
        color: $color_comet;
        font-size: 3.125rem;
        text-align: center;
      }

      small {
        display: block;
        font-style: italic;
        font-weight: 600;
        font-size: 1.125rem;
        text-align: center;
        color: $color_dove_gray;
      }
    }
  }

  .coins-explain:nth-of-type(2) {

    @media (min-width: 50em) {
      @include flex(1 1 auto);
      width: calc((100% - #{$highlights_margin_columns * 2}) / 2.0001);
      max-width: calc((100% - #{$highlights_margin_columns * 2}) / 2.0001);
      margin-right: #{$highlights_margin_columns / 2};
      margin-bottom: 2rem;
      margin-left: #{$highlights_margin_columns / 2};

      h3 {
        margin-top: 0;
      }
    }

    @media (min-width: 70em) {
      width: calc(((100% - #{$highlights_margin_columns * 4}) / 4.0001 * 3) + #{$highlights_margin_columns * 2});
      max-width: calc(((100% - #{$highlights_margin_columns * 4}) / 4.0001 * 3) + #{$highlights_margin_columns * 2});
    }
  }
}

.benefits-alert-link {
  color: red;
}