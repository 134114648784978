.hra_callout {
  $hra_callout_breakpoint_to_desktop: 60em;

  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  position: relative;
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding: 2rem;
  color: $color_white;
  text-decoration: none;
  background-color: $color_carrot_orange;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;

  @media (min-width: $hra_callout_breakpoint_to_desktop) {
    @include flex-direction(row);
    @include justify-content(space-between);
    max-width: 80rem;
    margin-right: auto;
    margin-left: auto;
  }

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    z-index: 2;
  }

  > div {
    position: relative;
    z-index: 3;

    header {
      font-weight: 600;
      font-size: 1.25rem;
    }

    p {
      font-size: .875rem;
      line-height: 1.5;
    }
  }

  a {
    position: relative;
    z-index: 3;
    margin-top: 3rem;
    padding: 0 2.75rem;
    color: $color_carrot_orange;
    font-weight: 600;
    font-size: .875rem;
    letter-spacing: .025em;
    line-height: 2.5rem;
    white-space: nowrap;
    background-color: $color_white;
    border-radius: 1000rem;

    @media (min-width: $hra_callout_breakpoint_to_desktop) {
      margin-top: 0;
      margin-left: 3rem;
    }
  }
}