$breakpoint_to_desktop_reg : 56.25em;
.site_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 4.5rem);

	> div[ng-app] {
		width: 100%;
	}
}

span.orange {
	color: $color_carrot_orange
}

article {
	width: 100%;

	section {
		&:not(:last-of-type) {
			border-bottom: 1px solid $color_alto;
		}

		.wrapper {
			width: 100%;
			max-width: 60rem;
			margin: 0 auto;
			padding: 5rem 1.5rem;
			text-align: center;

			h1 {
				color: $color_kimberly;
				font-weight: 400;
				@include scale(font-size, 1.5rem, 20rem, 2.625rem, 50rem);
				text-align: center;
			}

			p {
				line-height: 1.5625;
				text-align: center;

				&.confirm {
					font-size: 1.125rem;

					.email {
						color: $color_jaffa;
					}
				}

				.re-request {
					color: $color_mantis;
					text-decoration: none;
				}

				+ p {
					margin-top: 6rem;
				}
			}

			form button,
			.button {
				float: none;
				display: inline-block;
				min-width: 16rem;
				margin: 3rem auto;
				padding: .6875rem 3rem;
				letter-spacing: .1375em;
				background-color: $color_mantis;
				border-color: $color_mantis;
				border-radius: 4px;

				&:hover,
				&:focus {
					background-color: darken($color_mantis, 20);
					border-color: darken($color_mantis, 20);
				}
			}

			.member_info {
				.wrapper {
					> .top {
						margin-bottom: 2rem;
						padding-bottom: 2rem;
						border-bottom: 1px solid $color_alto;

						@media (min-width: $breakpoint_to_desktop_reg) {
							display: flex;
							align-items: center;
						}

						.left {
							@media (min-width: $breakpoint_to_desktop_reg) {
								flex: 1 1 50%;
								max-width: 50%;
							}

							&.inactive {
								opacity: .5;
							}
						}

						.separator {
							padding: 0 3rem;
							font-weight: 700;
							font-size: 1.75rem;

							@media (min-width: $breakpoint_to_desktop_reg) {
								flex: 0 1 auto;
							}
						}

						.right {
							@media (min-width: $breakpoint_to_desktop_reg) {
								flex: 1 1 50%;
								max-width: 50%;
							}

							&.inactive {
								opacity: .5;
							}

							.top {
								label div {
									display: flex;
									align-items: center;
								}
							}

							.bottom {
								display: flex;
								justify-content: center;

								.right {
									padding-left: 3rem;

									.select {
										&:after {
											top: calc(50% + .875rem);
										}
									}
								}
							}
						}
					}

					> .bottom {
						label {
							max-width: 25.625rem;
							margin: 0 auto;

							div {
								display: flex;
								align-items: center;
							}
						}
					}

					label {
						span:first-of-type:not(.error) {
							margin-bottom: 1rem;
							font-size: 1.375rem;

							a {
								margin-left: 1rem;
							}
						}

						.divider {
							display: block;
							width: 100%;
							font-size: 1.375rem;

						}

						input,
						select {
							text-align: center;
							border-radius: 4px;
							transition: 0.25s all;
							width: 100%;
							padding: .6875rem .875rem;
							color: #717171;
							font-family: inherit;
							line-height: 1.5;
							border: 1px solid #ccc;

							&:focus {
								border-color: $color_carrot_orange;
							}
						}

						.note {
							margin-top: 1rem;
							color: $color_kimberly;
							font-style: italic;
						}
					}
				}

				input[type=submit] {
					float: none;
					display: inline-block;
					min-width: 16rem;
					margin: 3rem auto;
					padding: .6875rem 3rem;
					letter-spacing: .1375em;
					background-color: $color_mantis;
					border-color: $color_mantis;
					border-radius: 4px;

					&:hover,
					&:focus {
						background-color: darken($color_mantis, 20);
						border-color: darken($color_mantis, 20);
					}
				}
			}

			.account_security {
				.wrapper {
					@media (min-width: $breakpoint_to_desktop_reg) {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
					}

					label {
						@media (min-width: $breakpoint_to_desktop_reg) {
							flex: 1 1 46%;
							width: 46%;
							max-width: 46%;
							margin: 1rem 0 2rem 0;
						}

						span:first-of-type:not(.error) {
							margin-bottom: 1rem;
							font-size: 1.375rem;

							a {
								margin-left: 1rem;
							}
						}

						input {
							text-align: center;
							border-radius: 4px;
							transition: 0.25s all;
							width: 100%;
							padding: .6875rem .875rem;
							color: $color_dove_gray;
							line-height: 1.5;
							border: 1px solid #ccc;

							&:focus {
								border-color: $color_carrot_orange;
							}
						}

						.note {
							margin-top: 1rem;
							color: $color_kimberly;
							font-style: italic;
							font-size: .875rem;
						}

						.tooltip {
							box-shadow: 0 0 0.9375rem rgba(0, 0, 0, 0.2);
							cursor: pointer;
							padding: 1rem;
							bottom: calc(100% + 1rem);
						}
					}
				}

				input[type=submit] {
					float: none;
					display: inline-block;
					min-width: 16rem;
					margin: 3rem auto;
					padding: .6875rem 3rem;
					letter-spacing: .1375em;
					background-color: $color_mantis;
					border-color: $color_mantis;
					border-radius: 4px;

					&:hover,
					&:focus {
						background-color: darken($color_mantis, 20);
						border-color: darken($color_mantis, 20);
					}
				}
			}

			.security_questions {
				.wrapper {
					@media (min-width: $breakpoint_to_desktop_reg) {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
					}

					label {
						@media (min-width: $breakpoint_to_desktop_reg) {
							flex: 1 1 46%;
							width: 46%;
							max-width: 46%;
						}

						span:not(.error) {
							margin-bottom: 1rem;
							font-size: 1.375rem;
							display: block;
							width: 100%;
						}

						&.select:after {
							// top: calc(50% + 1.375rem);
							top: calc(4rem);
						}

						input,
						select {
							text-align: center;
							border-radius: 4px;
							transition: 0.25s all;
							width: 100%;
							padding: .6875rem .875rem;
							color: #717171;
							font-family: inherit;
							line-height: 1.5;
							border: 1px solid #ccc;
						}
					}
				}

				input[type=submit] {
					float: none;
					display: inline-block;
					min-width: 16rem;
					margin: 3rem auto;
					padding: .6875rem 3rem;
					letter-spacing: .1375em;
					background-color: $color_mantis;
					border-color: $color_mantis;
					border-radius: 4px;

					&:hover,
					&:focus {
						background-color: darken($color_mantis, 20);
						border-color: darken($color_mantis, 20);
					}
				}
			}

			.pref_table {
				table-layout: initial;
				border: 0;

				thead {
					tr th {
						display: block;
						padding: 0;
						background-color: transparent;

						@media (min-width: 54em) {
							display: table-cell;
						}

						&:nth-of-type(1) {
							padding: 0 .75rem;
							font-weight: 700;
							font-size: 1rem;
							text-align: center;

							@media (min-width: 54em) {
								text-align: left;
							}
						}

						label {
							justify-content: center;
							margin: 1rem 0;
							display: flex;

							span {
								width: auto;
								padding-right: .5rem;
								color: $color_dove_gray;
								font-weight: 600;
							}

							input[type="radio"] {
								position: absolute;
								opacity: 0;
							}

							.radio {
								width: 1.125rem;
								height: 1.125rem;
								margin: 0;
								margin-top: .125rem;
								border: 1px solid $color_dusty_gray;
								border-radius: 50%;

							}


							input:checked + .radio {
								background-color: $color_jaffa;
								border-color: $color_jaffa;

								&:after {
									content: url('../../svgs/checkmark.svg');
									top: -.0875rem;
									position: absolute;
									width: 12px;
									height: 9px;
									background-color: transparent;
									border-radius: 0;
									transform: translate(0, 0);
								}
							}
						}
					}
				}

				tbody {
					tr td {
						display: block;
						background-color: transparent;

						@media (min-width: 54em) {
							display: table-cell;
							border-right: 1px solid $color_mercury;
							border-bottom: 1px solid $color_mercury;
						}

						&:nth-of-type(1) {
							text-align: center;

							@media (min-width: 54em) {
								text-align: left;
							}
						}

						&:nth-of-type(2),
						&:nth-of-type(3) {
							display: inline-block;
							width: 50%;
							text-align: center;
							border-bottom: 1px solid $color_mercury;

							&:after {
								content: attr(data-label);
							}

							@media (min-width: 54em) {
								display: table-cell;
								width: 6.25rem;

								&:after {
									display: none;
								}
							}
						}

						label {
							width: 1.875rem;
							height: 1.875rem;
							margin: 0 auto;


							.radio {
								width: 1.75rem;
								height: 1.75rem;
								margin: 0;
								border: 1px solid $color_dusty_gray;
								border-radius: 50%;
							}

							input[type="radio"] {
								position: absolute;
								opacity: 0;
							}


							input:checked + .radio {
								background-color: $color_jaffa;
								border-color: $color_jaffa;

								&:after {
									content: url('../../svgs/checkmark.svg');
									top: auto;
									left: .5rem;
									width: 12px;
									height: 9px;
									background-color: transparent;
									border-radius: 0;
									transform: translate(0, 0);
								}
							}
						}
					}
				}

				+ input[type=submit] {
					float: none;
					display: inline-block;
					min-width: 16rem;
					margin: 3rem auto;
					padding: .6875rem 3rem;
					letter-spacing: .1375em;
					background-color: $color_mantis;
					border-color: $color_mantis;
					border-radius: 4px;

					&:hover,
					&:focus {
						background-color: darken($color_mantis, 20);
						border-color: darken($color_mantis, 20);
					}
				}
			}

			.terms_scroller {
				position: relative;
				height: 24rem;

				li {
					text-align: left;
				}

				&:before {
					content: '';
					position: absolute;
					top: 0;
					right: 20px;
					bottom: calc(100% - 4rem);
					left: 0;
					background-image: linear-gradient(to bottom, rgba($color_white, 1) 0%, rgba($color_white, 0) 100%);
					z-index: 2;
				}

				&:after {
					content: '';
					position: absolute;
					top: calc(100% - 4rem);
					right: 20px;
					bottom: 0;
					left: 0;
					background-image: linear-gradient(to top, rgba($color_white, 1) 0%, rgba($color_white, 0) 100%);
					z-index: 2;
				}

				&.hide-top {
					&:before {
						content: none;
					}
				}

				&.hide-bottom {
					&:after {
						content: none;
					}
				}

				> div {
					position: relative;
					height: 24rem;
					z-index: 1;
					overflow-y: scroll;

					&::-webkit-scrollbar {
						background-color: $color_alto;
						border-radius: 100rem;
					}

					&::-webkit-scrollbar-thumb {
						background-color: $color_port_gore;
						border-radius: 100rem;
					}

					h2 {
						margin-bottom: .5rem;
						color: $color_kimberly;
						font-weight: 400;
						text-align: left;
					}

					h2 + p {
						margin-top: 0;
					}

					p {
						padding-right: 1.5rem;
						line-height: 1.6875;
						text-align: left;
					}

					p + h2 {
						margin-top: 3rem;
					}
				}

				+ form input[type=submit] {
					float: none;
					display: inline-block;
					min-width: 16rem;
					margin: 3rem auto;
					padding: .6875rem 3rem;
					letter-spacing: .1375em;
					background-color: $color_mantis;
					border-color: $color_mantis;
					border-radius: 4px;

					&:hover,
					&:focus {
						background-color: darken($color_mantis, 20);
						border-color: darken($color_mantis, 20);
					}
				}
			}

			.verification {
				label {
					> span:first-of-type {
						margin-bottom: 1.5rem;
						font-size: 1.375rem;
						display: block;
						width: 100%;
						letter-spacing: .056875em;
					}

					input {
						max-width: 23.75rem;
						margin: 0 auto;
						text-align: center;
						border-radius: 4px;
						transition: 0.25s all;
						width: 100%;
						padding: .6875rem .875rem;
						color: $color_dove_gray;
						line-height: 1.5;
						border: 1px solid #ccc;
					}
				}

				input[type=submit] {
					float: none;
					display: inline-block;
					min-width: 16rem;
					margin: 0.75rem auto;
					padding: .6875rem 3rem;
					letter-spacing: .1375em;
					background-color: $color_mantis;
					border-color: $color_mantis;
					border-radius: 4px;

					&:hover,
					&:focus {
						background-color: darken($color_mantis, 20);
						border-color: darken($color_mantis, 20);
					}
				}

				.success {
					color: $color_mantis;
				}
			}

			.login-button {
				float: none;
				display: inline-block;
				min-width: 16rem;
				margin: 3rem auto;
				padding: .6875rem 3rem;
				letter-spacing: .1375em;
				color: white;
				background-color: $color_mantis;
				border-color: $color_mantis;
				border-radius: 4px;

				&:hover,
				&:focus {
					background-color: darken($color_mantis, 20);
					border-color: darken($color_mantis, 20);
				}
			}
		}

		span.error {
			padding: .375rem;
			color: $color_error;
			font-size: .875rem;
		}
	}
}


.tooltip.password-rules {
	margin: 0;
	padding: 1rem;

	p, span {
		margin: 0;
		padding: 0;
		color: $color_form_errors;

		&.success {
			color: green;
		}
	}
}


article.registration {
	.wrapper p + p{
		margin-top: 4rem;
		display: flex;

		a {
			letter-spacing: .08rem;
		}

		a + a {
			margin-left: 1rem;
		}
	}

	form {
		button, input[type="submit"] {
			color: white !important;
		}
	}

	button[type=submit] {
		color: white !important;

		&:disabled {
			background-color: lightgray;
			border-color: lightgray;
			cursor: not-allowed;
		}
	}

	.help-text {
		margin: 1rem 0;
		text-align: left;

		.arrows {

			display: inline-block;
			height: 100%;

			svg {
				height: 15px;

				g {
					fill: $color_carrot_orange;
				}
			}
		}

		div {
			margin-left: 1.1rem;
			display:inline-block;
			width: 80%;
		}
	}
}

