div.breadcrumbs.separate svg {
	position: relative;
    top: -2px;
    width: 11px;
    height: 7px;
    margin: 0 .5rem;
}

.basic-plan-search {
	@media (min-width: 60em) {
		.form-row-container {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.form-row.split {
				flex: 1 1 48%;
				max-width: 48%;
			}
			button {
				margin-top: 0;
				margin-bottom: 2rem;
			}
		}

	}
}
.quote-results {
	width: 100%;
	max-width: 80rem;
	margin-right: auto;
	margin-left: auto;

	.subhead {
		color: rgb(113, 113, 113);
	}

	$breakpoint_to_full_table : 68em;
	table {
		table-layout: auto;
		display: block;
		width: 100%;
		border-collapse: collapse;
		margin: 3rem auto;
		border: 0;

		@media (min-width: $breakpoint_to_full_table) {
			display: table;
		}

		thead {
			display: block;
			width: 100%;

			@media (min-width: $breakpoint_to_full_table) {
				display: table-header-group;
			}

			tr {
				display: block !important;
				border-color: transparent;

				@media (min-width: $breakpoint_to_full_table) {
					display: table-row !important;
				}

				th {
					display: none;
					font-weight: 700;
					text-align: center;

					@media (min-width: $breakpoint_to_full_table) {
						display: table-cell;
					}

					&:nth-of-type(even) {
						color: $color_white;
						background-color: $color_kimberly;
					}

					&:nth-of-type(odd) {
						color: $color_white;
						background-color: $color_dolphin;
					}

					&:first-of-type {
						display: block;
						text-align: center;
						background-color: transparent;
						border: 0;

						@media (min-width: $breakpoint_to_full_table) {
							display: table-cell;
							text-align: left;
						}

						div {
							color: $color_comet;
							font-weight: 400;
							font-size: 2rem;
						}

						a {
							font-size: 1rem;

							span {
								display: inline-block;
								width: .875rem;
								height: .625rem;
								margin-left: .75rem;

								svg {
									display: block;
									width: .875rem;
									height: .625rem;

									path {
										fill: currentColor;
									}
								}
							}
						}
					}

					&:nth-of-type(2) {
						border-top-left-radius: .25rem;
					}

					&:last-of-type {
						border-top-right-radius: .25rem;
					}
				}
			}
		}

		tbody {
			display: block;

			@media (min-width: $breakpoint_to_full_table) {
				display: table-row-group;
			}

			tr {
				display: block;
				margin-top: 1.875rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border: 1px solid $color_alto;

				@media (min-width: $breakpoint_to_full_table) {
					display: table-row;
					border: 0;
				}

				&:nth-of-type(even) {
					background-color: $color_alabaster;
				}

				&:last-of-type {
					background-color: $color_white;
				}

				td {
					display: block;
					padding: .25rem .75rem;
					text-align: center;
					background-color: transparent;

					&:before {
						content: attr(data-label) ": ";
						padding-right: .5rem;
						font-weight: 600;
					}

					@media (min-width: $breakpoint_to_full_table) {
						display: table-cell;
						padding: 1rem .75rem;

						&:before {
							display: none;
						}
					}

					&:first-of-type {
						display: block;
						margin-bottom: .625rem;
						font-weight: 600;
						font-size: 1.125rem;
						text-align: center;

						&:before {
							display: none;
						}

						&:after {
							content: '';
							display: block;
							width: 80%;
							margin: 0 auto;
							padding: .25rem 0 .5rem 0;
							border-bottom: 1px solid $color_alto;
						}

						@media (min-width: $breakpoint_to_full_table) {
							display: table-cell;
							text-align: left;

							&:after {
								display: none;
							}
						}
					}

					&:last-of-type {
						border-bottom: 0 !important;
					}

					@media (min-width: $breakpoint_to_full_table) {
						&:not(.no_border) {
							border: 1px solid $color_alto;
						}
					}
				}

				&:last-of-type {
					td {
						&::before {
							padding-right: 0;
						}

						&::after {
							display: none;
						}

						@media (min-width: $breakpoint_to_full_table) {
							&:nth-of-type(n + 2) {
								border-bottom: 1px solid $color_alto !important;
							}
						}

						a {
							justify-content: center;
						}
					}
				}
			}
		}
	}
}
