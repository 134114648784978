.switch-body {
  $toggle-control-color: white;
  $toggle-width: 60px;
  $toggle-height: 25px;
  $toggle-gutter: 5px;
  $toggle-radius: 50%;
  $toggle-control-speed: .15s;
  $toggle-control-ease: ease-in;

  // These are our computed variables
  // change at your own risk.
  $toggle-radius: $toggle-height / 2;
  $toggle-control-size: $toggle-height - ($toggle-gutter * 2);

  .toggle-control {
    display: block;
    position: relative;
    //padding-left: $toggle-width;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    input:checked ~ .control {
      background-color: $color_carrot_orange;

      &:after {
        left: $toggle-width - $toggle-control-size - $toggle-gutter;
      }
    }

    .control {
      position: absolute;
      top: 0;
      left: 0;
      height: $toggle-height;
      width: $toggle-width;
      border-radius: $toggle-radius;
      background-color: $color_comet;
      transition: background-color $toggle-control-speed $toggle-control-ease;

      @media(max-width: 72em) {
        left: 50%;
      }

      &:after {
        content: "";
        position: absolute;
        left: $toggle-gutter;
        top: $toggle-gutter;
        width: $toggle-control-size;
        height: $toggle-control-size;
        border-radius: $toggle-radius;
        background: $toggle-control-color;
        transition: left $toggle-control-speed $toggle-control-ease;
      }
    }
  }

  .switch-label {
    color: $color_comet;
    font-weight: bold;
    padding-top: .9rem;
    padding-right: .2rem;
    min-width: 100%;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 7vh;
  margin: auto;
  padding-bottom: .2rem;

  @media(max-width: 72em) {
    flex-flow: column;
    padding-bottom: 1rem;
  }
}
