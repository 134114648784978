.banner {
	width: 100%;

	&.interior {
		position: relative;
		min-height: 21.875rem;
		background: transparent center / cover no-repeat scroll;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $color_secondary_purple;
			opacity: .85;
			z-index: 1;
		}

		h1 {
			position: absolute;
			top: 50%;
			right: 0;
			left: 0;
			margin: 0;
			padding: 0 1.5rem;
			color: white;
			text-align: center;
			z-index: 2;
			transform: translateY(-50%);
		}
	}

	&.sbs {
		$breakpoint_to_sbs : 50em;
		display: flex;
		flex-direction: column;

		@media (min-width: $breakpoint_to_sbs) {
			flex-direction: row;
		}

		.img {
			position: relative;
			flex: 1 1 50%;
			height: 17.1875rem;

			@media (min-width: $breakpoint_to_sbs) {
				height: 34.375rem;
			}

			.bg {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: transparent center / cover no-repeat scroll;
			}
		}

		.content {
			flex: 1 1 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding-top: 2.5rem;
			@include scale(padding-right, 1.5rem, 20em, 2.5rem, $breakpoint_to_sbs);
			@include scale(padding-right, 1.5rem, $breakpoint_to_sbs, 2.5rem, 80em);
			padding-bottom: 2.5rem;
			@include scale(padding-left, 1.5rem, 20em, 2.5rem, $breakpoint_to_sbs);
			@include scale(padding-left, 1.5rem, $breakpoint_to_sbs, 2.5rem, 80em);
			color: white;
			background-image: linear-gradient(to bottom, $color_secondary_purple 0%, $gradient_purple_right 100%);

			@media (min-width: $breakpoint_to_sbs) {
				height: 34.375rem;
			}

			h1 {
				margin-top: 0;
				color: inherit;
			}
		}
	}
}